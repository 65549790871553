import axios from 'axios'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getVerificationsDetail,
  openModal,
} from '../../features/admin/verificationSlice'
import { getJwtFromLocal } from '../../utils/getJwt'
import { AiOutlineSearch } from 'react-icons/ai'

const fetchTripLeaders = async () => {
  const { isLocal, jwt } = getJwtFromLocal('ADMIN')

  if (!isLocal) {
    return
  }

  const headers = {
    Authorization: `Bearer ${jwt}`,
  }

  const res = await axios(
    `${process.env.REACT_APP_API_URL}/admin/verifications`,
    { headers }
  )
  return res
}

export default function VerificationAdmin() {
  const { data, status, refetch } = useQuery('tripleaders', fetchTripLeaders)
  const dispatch = useDispatch()
  const { isRefetch } = useSelector((store) => store.verifications)

  const openModalVerification = (username) => {
    dispatch(getVerificationsDetail(username))
    dispatch(openModal())
  }

  useEffect(() => {
    if (isRefetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [isRefetch])

  useEffect(() => {
    if (status === 'error') {
      toast.error('Gagal terkoneksi', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }, [status])

  if (status === 'loading') {
    return (
      <>
        <p className='text-xl mb-2 text-[#303030] pb-8'>Payment</p>
        <div className='h-fit px-4 bg-white rounded'>
          <div className='py-4 px-5 flex justify-between'>
            <div className='flex items-center gap-2'>
              <input
                className='p-1 w-14 border border-[#CCCCCC] rounded outline-none'
                type='number'
              />
              <p className='text-[#212529] text-sm'>entries per page</p>
            </div>
            <div className='flex items-center gap-2'>
              <p className='text-[#7A7A7A]'>Search:</p>
              <div className='relative h-fit'>
                <input className='p-2 rounded outline-none border border-[#CCCCCC] max-w-40' />
                <div className='absolute left-2 top-1/2 -translate-y-1/2'>
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead>
              <tr className='text-[#111111]'>
                <th className='py-4'>Name</th>
                <th className='py-4'>Email</th>
                <th className='py-4'>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan='3'>
                  <svg
                    className='animate-spin mx-auto h-5 w-5 text-white'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                  <p>Loading ...</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
  if (status === 'error') {
    return (
      <>
        <p className='text-xl mb-2 text-[#303030] pb-8'>Payment</p>
        <div className='h-48 px-4 bg-white rounded'>
          <div className='py-4 px-5 flex justify-between'>
            <div className='flex items-center gap-2'>
              <input
                className='p-1 w-14 border border-[#CCCCCC] rounded outline-none'
                type='number'
              />
              <p className='text-[#212529] text-sm'>entries per page</p>
            </div>
            <div className='flex items-center gap-2'>
              <p className='text-[#7A7A7A]'>Search:</p>
              <div className='relative h-fit'>
                <input className='p-2 rounded outline-none border border-[#CCCCCC] max-w-40' />
                <div className='absolute left-2 top-1/2 -translate-y-1/2'>
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead>
              <tr className='text-[#111111]'>
                <th className='py-4 text-left'>Name</th>
                <th className='py-4 text-left'>Email</th>
                <th className='py-4 text-center'>Status</th>
              </tr>
            </thead>
          </table>
        </div>
      </>
    )
  }

  if (status === 'success') {
    return (
      <>
        <p className='text-xl mb-2 text-[#303030] pb-8'>Data Pembayaran</p>
        <div className='h-fit pb-6 px-4 bg-white rounded'>
          <div className='py-4 px-5 flex justify-between'>
            <div className='flex items-center gap-2'>
              <input
                className='p-1 w-14 border border-[#CCCCCC] rounded outline-none'
                type='number'
              />
              <p className='text-[#212529] text-sm'>entries per page</p>
            </div>
            <div className='flex items-center gap-2'>
              <p className='text-[#7A7A7A]'>Search:</p>
              <div className='relative h-fit'>
                <input className='p-2 rounded outline-none border border-[#CCCCCC] max-w-40' />
                <div className='absolute left-2 top-1/2 -translate-y-1/2'>
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead>
              <tr className='text-[#111111]'>
                <th className='py-4 text-left'>Name</th>
                <th className='py-4 text-left'>Email</th>
                <th className='py-4 text-center'>Status</th>
              </tr>
            </thead>
            <tbody>
              <TableTripLeaders
                openModalVerification={openModalVerification}
                dataTripLeaders={data.data.data}
              />
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

// table
const TableTripLeaders = ({ openModalVerification, dataTripLeaders }) => {
  return (
    <>
      {dataTripLeaders.map(({ name, username, email }, index) => (
        <tr key={index}>
          <td>{name}</td>
          <td>{email}</td>
          <td>
            <button
              className='py-3 h-10 flex items-center justify-center rounded w-full border border-[#5331CE] text-[#5331CE] hover:bg-[#5331CE] hover:text-white'
              onClick={() => openModalVerification(username)}
            >
              Verifikasi
            </button>
          </td>
        </tr>
      ))}
    </>
  )
}
