import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import ttripLogo from "../../assets/images/ttrip.png";

const Footer = () => {
  return (
    <footer className="h-full md:h-[32rem] bg-main-darkest text-white py-14">
      <div className="footer-wrapper container mx-auto flex flex-col gap-12 h-full">
        <div className="find-us flex justify-between items-center border-b border-b-slate-700 pb-9">
          <div className="flex gap-1 items-center">
            <div className="font-bold text-sm mr-4">TEMUKAN KAMI DI:</div>
            <a
              href="https://www.facebook.com/people/Temantrip/100088092804324/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:transition-all hover:bg-main-color p-2 rounded"
            >
              <FaFacebookF size={14} />
            </a>
            <a
              href="https://www.instagram.com/temantripindonesia/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:transition-all hover:bg-main-color p-2 rounded"
            >
              <FaInstagram size={16} />
            </a>
            <a
              href="https://www.linkedin.com/m/company/temantrip/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:transition-all hover:bg-main-color p-2 rounded"
            >
              <FaLinkedinIn size={16} />
            </a>
          </div>
          <div className="timan-top-footer-logo hidden lg:block">
            <img
              src={ttripLogo}
              alt="Teman trip logo"
              className="grayscale invert w-16"
            />
          </div>
        </div>
        <div className="flex gap-20 grow flex-wrap md:flex-nowrap">
          <div className="about-container mr-24">
            <div className="about w-72">
              <div className="text-main-color font-bold text-2xl mb-4">
                Temantrip
              </div>
              <p className="leading-7 text-justify text-sm text-gray-400">
                Temantrip adalah platform opentrip dibawah PT Tariana Sejahtera Travel dengan NIB: 2812220004064
                ber-headquarter di Jember, Jawa Timur, Indonesia.
              </p>
            </div>
          </div>
          <div className="travel flex flex-col gap-2 whitespace-nowrap">
            <div className="font-bold mb-4 leading-8">Travel</div>
            <Link
              to="/trips"
              className="hover:text-white hover:transition-all text-sm text-gray-400"
            >
              Ikut Trip
            </Link>
            <Link
              to="/"
              className="hover:text-white hover:transition-all text-sm text-gray-400"
            >
              Buat Trip
            </Link>
            <Link
              to="/carakerja"
              className="hover:text-white hover:transition-all text-sm text-gray-400"
            >
              Cara Kerja
            </Link>
          </div>
          <div className="company flex flex-col gap-2">
            <div className="font-bold mb-4 leading-8">Company</div>
            <Link
              to="/aboutus"
              className="hover:text-white hover:transition-all text-sm text-gray-400"
            >
              About Us
            </Link>
            <Link
              to="/contactus"
              className="hover:text-white hover:transition-all text-sm text-gray-400"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="copy justify-self-end mt-8">
          <p className="align-self-end font-light text-xs text-slate-400">
            Timantrip &copy;2023. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
