import React from 'react'
import IconData from '../../assets/icons/IconsDataTrip.svg'
import IconPrice from '../../assets/icons/IconsRincianBiaya.svg'
import IconPreview from '../../assets/icons/IconsPreviewTrip.svg'
import IconVerification from '../../assets/icons/IconsVerification.svg'

export default function Stepper({ steps }) {
  return (
    <div className='flex justify-between items-center gap-2'>
      {steppers.map((step, index) => (
        <React.Fragment key={index}>
          <div className='flex items-center'>
            <div className='flex flex-col justify-center items-center'>
              <div
                className={`w-[44px] h-[44px] rounded-full flex justify-center items-center 
                ${
                  steps === index || index < steps
                    ? 'bg-[#5331CE]'
                    : 'bg-[#5331CE]/40'
                }`}
              >
                <img src={step.image} alt='icons' />
              </div>
              <p
                className={`mt-2 text-center
              ${
                steps === index || index < steps
                  ? 'text-black font-medium'
                  : 'text-black/50'
              }`}
              >
                {step.name}
              </p>
            </div>
          </div>
          {index < steppers.length - 1 ? (
            <div className='-mt-6 w-24 h-2 bg-gray-300 rounded-full'></div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  )
}

const steppers = [
  {
    name: 'Isi Data Trip',
    image: IconData,
  },
  {
    name: 'Rincian Biaya',
    image: IconPrice,
  },
  {
    name: 'Preview Trip',
    image: IconPreview,
  },
  {
    name: 'Verifikasi Trip',
    image: IconVerification,
  },
]
