import Button from "../Components/Form/Button";
import Avatar, { genConfig } from "react-nice-avatar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { getHeadersConfig } from "../utils/authHelpers";
import { toast } from "react-toastify";
import ErrorComponent from "../Components/Fallbacks/ErrorComponent";
import TripBlock from "../Components/Cards/TripBlock";
import trips from "../assets/sample_data/trips.json";
import { Link } from "react-router-dom";
import LoadingSkeleton from "../Components/Fallbacks/LoadingSkeleton";

const UserProfile = () => {
  const username = useSelector((state) => state.auth.username) || "";
  const avatarConfig = genConfig(username);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    const config = getHeadersConfig();
    if (username) {
      setIsError(false);
      axios
        .get(process.env.REACT_APP_API_URL + "/profile", config)
        .then((res) => {
          setUserProfile(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setIsError(true);
          toast.error("Terjadi kesalahan, silahkan coba lagi", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      setIsError(true);
    }
  }, [username]);

  if (isError) return <ErrorComponent />;

  return (
    <main className="container pt-28 min-h-screen">
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <div id="profile-wrapper" className="flex gap-5 mb-32">
          <div
            id="user-profile"
            className="w-64 bg-white border rounded min-h-screen shrink-0 flex flex-col items-center gap-5 pt-10"
          >
            <div className="avatar rounded-full border-4 border-main-color">
              <Avatar className="w-24 h-24" {...avatarConfig} />
            </div>
            <div className="text-center">
              <strong id="name">{userProfile?.name}</strong>
              <p>{userProfile?.email}</p>
            </div>
            <div>
              <Button link={`/profile/${username}/verify`} small>
                Jadi Pemandu
              </Button>
            </div>
            <div>
              <Button small secondary>
                Sunting Profil
              </Button>
            </div>
          </div>
          <div
            id="detail-tab"
            className="w-full border rounded bg-white min-w-fit"
          >
            <div className="tab-wrapper p-10">
              <div className="my-trip mb-8">
                <h1 className="text-2xl mb-6 font-bold">Perjalanan Saya</h1>
                <p className="text-gray-400">
                  Anda belum memiliki perjalanan dalam waktu dekat.&nbsp;
                  <Link
                    to={"/trips"}
                    className="text-main-color hover:underline"
                  >
                    Temukan perjalanan
                  </Link>
                </p>
              </div>
              <div className="past-trips">
                <h1 className="font-bold text-2xl mb-6">
                  Perjalanan Sebelumnya
                </h1>
                <div className="user-trip-list-wrapper">
                  {trips.slice(0, 3).map((trip) => {
                    return <TripBlock key={trip.id} trip={trip} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};
export default UserProfile;
