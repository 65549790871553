import Carousel from '../Components/Carousel'
import Hero from '../Components/Layouts/Hero'
import {
  AiOutlineCheckCircle,
  AiOutlineCar,
  AiOutlineFileDone,
  AiOutlineSmile,
} from 'react-icons/ai'
import { MdOutlineNaturePeople, MdPeopleOutline } from 'react-icons/md'
import TripCard from '../Components/Cards/TripCard'
import { useQuery } from 'react-query'
import axios from 'axios'
import Loading from '../Components/Fallbacks/LoadingSkeleton'

async function getProducts() {
  const data = await axios(`${process.env.REACT_APP_API_URL}/products`)
  return data
}

const Homepage = () => {
  const { status, data } = useQuery('productsHomepage', getProducts)

  return (
    <div className='homepage bg-slate-200'>
      <Hero />
      <main>
        <section
          id='showcase-counter'
          className='showcase-container bg-slate-100 pb-12'
        >
          <div className='showcase container mx-auto pt-12 lg:pt-0 w-full relative min-h-[32rem] mb-32 lg:mb-16'>
            <Carousel
              className='lg:-mt-32 absolute'
              style={{ left: '50%', transform: 'translate(-50%, 0' }}
            />
          </div>
          <div className='counters-section flex gap-5 pb-12'>
            <div className='counters-wrapper grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-3 justify-center gap-12 font-semibold mx-auto text-gray-400'>
              <div className='flex gap-6 items-center basis-1/5'>
                <AiOutlineCheckCircle size={48} className='shrink-0' />
                <div>
                  <div className='font-bold text-4xl text-main-color mb-2'>
                    45
                  </div>
                  <div>PEMANDU</div>
                </div>
              </div>
              <div className='flex gap-6 items-center basis-1/5'>
                <AiOutlineCar size={48} className='shrink-0' />
                <div>
                  <div className='font-bold text-4xl text-main-color mb-2'>
                    178
                  </div>
                  <div>DESTINASI</div>
                </div>
              </div>
              <div className='flex gap-6 items-center basis-1/5'>
                <AiOutlineFileDone size={48} className='shrink-0' />
                <div>
                  <div className='font-bold text-4xl text-main-color mb-2'>
                    67
                  </div>
                  <div className='whitespace-nowrap'>PAKET PERJALANAN</div>
                </div>
              </div>
              <div className='flex gap-6 items-center basis-1/5'>
                <AiOutlineSmile size={48} className='shrink-0' />
                <div>
                  <div className='font-bold text-4xl text-main-color mb-2'>
                    488
                  </div>
                  <div>PENJELAJAH</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='trip-list' className='bg-white py-24'>
          <div className='container mx-auto'>
            <div className='text-center mb-16'>
              <h2 className='text-3xl font-bold mb-4'>Trip Pilihan</h2>
              <p className='font-light text-slate-500 text-lg px-30 xl:px-72 2xl:px-96'>
                Perjalanan yang dipimpin oleh trip leader yang berpengalaman,
                yang membuat pengalaman perjalanan anda jauh lebih menyenangkan.
              </p>
            </div>
            <div className='trip-list-wrapper grid grid-cols-1 place-items-center justify-items-center md:grid-cols-2 xl:grid-cols-3 gap-6 2xl:mx-32'>
              {status === 'loading' ? <Loading /> : null}
              {status === 'success'
                ? data.data.data.map((trip, index) => (
                    <TripCard trip={trip} key={index} />
                  ))
                : null}
            </div>
          </div>
        </section>

        <section className='flex container mx-auto text-center'>
          <div className='wrapper flex flex-wrap mx-auto p-3 m-3 md:p-20 md:m-20'>
            <div className='bg-white py-10 px-14 md:py-20 md:px-28 basis-full lg:basis-1/2'>
              <MdOutlineNaturePeople size={42} className='mb-4 mx-auto' />
              <h2 className='text-2xl font-bold mb-5'>TRIP UNIK</h2>
              <p className='text-lg font-light'>
                Setiap trip disusun unik dengan ciri khas masing- masing. Tidak
                ada dua trip yang sama disini!
              </p>
            </div>
            <div className='bg-main-darker text-white py-10 px-14 md:py-20 md:px-28 basis-full lg:basis-1/2'>
              <MdPeopleOutline size={42} className='mb-4 mx-auto' />
              <h2 className='text-2xl font-bold mb-5'>BERSAMA LEBIH BAIK</h2>
              <p className='text-lg font-light'>
                Travel bersama teman baru membuat pengalaman menjadi lebih
                menyenangkan .
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
export default Homepage
