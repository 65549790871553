import Input from '../Form/Input';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getJwtFromLocal } from '../../utils/getJwt';
import Stepper from './Stepper';
import image from '../../assets/trip.png';
import imageKtp from '../../assets/ktp.png';

//form validator sample
const verifySchema = yup.object({
  nik: yup.string().required(),
});

const ProfileVerifyTrips = ({ handleStep, user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(verifySchema) });

  const handleVerifySubmit = async data => {
    const { isLocal, jwt } = getJwtFromLocal();

    if (!isLocal) {
      return;
    }

    const body = {
      nik: data.nik,
      no_hp: data.no_hp,
      address: data.address,
      image: data.image[0],
      linkedin: data.linkedin,
      twitter: data.twitter,
      facebook: data.facebook,
      instagram: data.instagram,
      bank_name: data.bank_name,
      account_id: user.user_id,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/profile/verification`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(res => {
        if (res.status === 201) {
          toast.success('berhasil dikirim', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <main className="container h-fit pt-32 mb-40 px-4 md:px-0">
      <div className="flex gap-[80px] flex-col md:flex-row justify-center">
        <div className="w-[633px] max-w-full">
          <p className="text-[28px] mb-8">Verifikasi Data Pribadi</p>
          <Stepper steps={3} />
          <div className="mt-[32px] rounded bg-[#FAFAFA] pt-[12px] px-[24px] pb-[24px] mb-[24px]">
            <p className="text-[#6750A4] font-semibold mb-[12px]">Panduan Verifikasi</p>
            <div className="flex items-center gap-6">
              <img
                className="w-[160px] object-cover"
                alt="panduan verifikasi data"
                src={imageKtp}
              />
              <ul className="list-disc text-[12px] leading-relaxed">
                <li>Pastikan KTP masih berlaku</li>
                <li>Pastikan Posisi KTP ada di tengah dan bisa terlihat secara keseluruhan</li>
                <li>Pastikan benar-benar foto, bukan scan atau fotokopi</li>
              </ul>
            </div>
          </div>
          <form onSubmit={handleSubmit(handleVerifySubmit)}>
            <label htmlFor="image" className="block mt-6 text-[14px] text-gray-400">
              Upload Photo KTP
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="file"
              name="image"
              id="image"
              required
              {...register('image')}
            />
            <label htmlFor="nik" className="block mt-6 text-[14px] text-gray-400">
              Nomor Induk (NIK)
            </label>
            <Input
              placeholder="648548745215XXXX"
              className="grow mb-0"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="nik"
              max="16"
              min="16"
              {...register('nik')}
              required
              errors={errors?.nik?.message}
            />
            <p className="text-[18px] font-semibold my-[12px]">Data Diri</p>
            <label htmlFor="fullname" className="block mt-6 text-[14px] text-gray-400">
              Nama Lengkap
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="text"
              name="fullname"
              id="fullname"
              required
              {...register('fullname')}
            />

            <label htmlFor="tel" className="block mt-6 text-[14px] text-gray-400">
              Nomor Telepon
            </label>
            <Input
              placeholder="085122334XXX"
              className="w-full"
              name="no_hp"
              {...register('no_hp')}
              required
              errors={errors?.no_hp?.message}
            />
            <label htmlFor="address" className="block mt-6 text-[14px] text-gray-400">
              Alamat
            </label>
            <Input
              placeholder="Jl. Kaliurang No. 34B Umbulmartani, Sleman"
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="address"
              {...register('address')}
              required
              errors={errors?.address?.message}
            />

            <label htmlFor="bank_name" className="block mt-6 text-[14px] text-gray-400">
              Bank
            </label>
            <Input
              className="w-full"
              name="bank_name"
              id="bank_name"
              {...register('bank_name')}
              required
              errors={errors?.bank_name?.message}
            />

            <p className="text-[18px] font-semibold my-[12px]">Social Media (min 2)</p>

            <label htmlFor="Facebook" className="block mt-6 text-[14px] text-gray-400">
              Facebook
            </label>
            <Input
              placeholder="https://facebook.com/"
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="facebook"
              {...register('facebook')}
              errors={errors?.facebook?.message}
            />
            <label htmlFor="Instagram" className="block mt-6 text-[14px] text-gray-400">
              Instagram
            </label>
            <Input
              placeholder="https://instagram/"
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="instagram"
              {...register('instagram')}
            />
            <label htmlFor="LinkedIn" className="block mt-6 text-[14px] text-gray-400">
              LinkedIn
            </label>
            <Input
              placeholder="https://www.linkedin.com/in/"
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="linkedin"
              {...register('linkedin')}
            />
            <label htmlFor="Twitter" className="block mt-6 text-[14px] text-gray-400">
              Twitter
            </label>
            <Input
              placeholder="https://twitter.com/"
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="twitter"
              {...register('twitter')}
            />
            <div className="flex justify-between mt-[12px] font-medium gap-[24px]">
              <button
                onClick={() => handleStep(prev => prev - 1)}
                className="flex-1 rounded-full px-3 py-6 border border-[#5331CE] text-[#5331CE]">
                Kembali
              </button>
              <button
                type="submit"
                className="flex-2 rounded-full px-3 py-6 border border-[#5331CE] bg-[#5331CE] text-white w-[502px]">
                Selesai
              </button>
            </div>
          </form>
        </div>
        <div className="w-[486px] max-w-full h-[800px] bg-gray-200 rounded-lg relative hidden md:block">
          <img
            src={image}
            className="w-full h-full object-cover object-center rounded-lg"
            alt="cover trip"
          />
        </div>
      </div>
    </main>
  );
};

export default ProfileVerifyTrips;
