import Payment from '../../Pages/Payment'
import { BsWhatsapp } from 'react-icons/bs'
import Logo from '../../assets/images/ttrip.png'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default function Confirm() {
  const { slug } = useParams()
  const [detail, setDetail] = useState([])

  const getDetail = async () => {
    const url = await fetch(`${process.env.REACT_APP_API_URL}/products/${slug}`)
    const data = await url.json()
    setDetail(data.data)
  };

  useEffect(() => {
    getDetail()
  }, [])
  return (
    <Payment>
      <div className={div}>
        <div>
          <h1 className={h1}>
              Silahkan Transfer {detail.price}
          </h1>
          <div className={div2}>
            <p>BNI 1586150292</p>
          </div>
          <div className='flex gap-3 justify-center items-center'>
            <h2 className='my-4 font-bold'>
              Atas Nama Tatiana Sejahtera Travel
            </h2>
            <img className='w-10 h-9' src={Logo} alt='ttrip' />
          </div>

          <a
            href='https://api.whatsapp.com/send/?phone=6282144234649&text=Confirm&type=phone_number&app_absent=0'
            className={a}
          >
            <h2>Konfirmasi Pembayaran</h2>
            <BsWhatsapp size='20' color='green' />
          </a>
        </div>
      </div>
    </Payment>
  )
}

const div = 'items-center w-[100%] my-20 col-span-2'
const h1 = 'font-bold text-lg text-violet-600 mb-10'
const div2 = 'flex flex-col gap-2 my-2 text-sm text-gray-900'
const a = 'font-bold text-sm my-10 flex w-full justify-center gap-5 items-center'
