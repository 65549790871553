import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Sidebar from '../../Components/Layouts/Profile/Sidebar';
import Input from '../../Components/Form/Input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';

// next todo btn ubah data!!

export default function ProfileBio() {
  const username = useSelector(state => state.auth.username) || '';
  const [results, setResults] = useState([]);

  const valid = yup.object().shape({
    nama: yup.string().required('Nama wajib di isi'),
    no_hp: yup
      .string()
      .required('No Handphone Wajib Di isi')
      .min(10, 'Nomor hp minimal 10 karakter'),
    desc: yup.string().required('Descrption Wajib di isi'),
    visit: yup.string().required('Visit Wajib di isi'),
    instagram: yup.string().required('Instagram is required'),
    facebook: yup.string().required('Facebook is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(valid),
  });

  const handleData = data => {
    const postVerif = async () => {
      const jwt = JSON.parse(localStorage.getItem('user'));
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${jwt}`);
      let formdata = new FormData();
      formdata.append('nama', data.nama);
      formdata.append('no_hp', data.no_hp);
      formdata.append('description', data.desc);
      formdata.append('visit', data.visit);
      formdata.append('instagram', data.instagram);
      formdata.append('facebook', data.facebook);

      let req = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
      };

      const api = await fetch(`${process.env.REACT_APP_API_URL}/profile`, req);
      if (api.status === 500) {
        return error500();
      } else {
        const json = await api.json();
        setResults(json);
        succes();
      }
    };

    postVerif();
  };

  const succes = () => {
    toast.success('data sudah dikirim!', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  const error500 = () => {
    toast.error('data verfikasi sudah ada', {
      position: 'top-center',
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <div className="container mx-auto pt-28 flex gap-[80px] mb-20">
      <Sidebar activeLink={0} username={username} isTripLeader={false} />
      <div className="w-[633px] max-w-full">
        <div className="flex justify-between">
          <p className="text-[28px] text-main-color">Biodata Diri</p>
          <button className="rounded-full px-6 h-[50px] border border-[#5331CE] text-[#5331CE] hover:bg-[#5331CE] hover:text-white">
            Ubah Data
          </button>
        </div>
        <p className="text-[#444444] text-sm mt-3.5">
          <span className="text-red-600">*</span> Mohon untuk mengisi data secara benar
        </p>
        <p className="mt-3 mb-1 font-semibold text-lg">Data Diri</p>
        <form onSubmit={handleSubmit(handleData)}>
          <label htmlFor="nama" className="block mt-3 mb-1 text-sm text-gray-400">
            Nama
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="nama"
            id="nama"
            {...register('nama')}
          />
          <span className="text-xs text-red-500">{errors.nama?.message}</span>

          <label htmlFor="no_hp" className="block mt-3 mb-1 text-sm text-gray-400">
            Nomor Handphone
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="no_hp"
            id="no_hp"
            {...register('no_hp')}
          />
          <span className="text-xs text-red-500">{errors.no_hp?.message}</span>

          <label htmlFor="desc" className="block mt-3 mb-1 text-sm text-gray-400">
            Description
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="desc"
            id="desc"
            {...register('desc')}
          />
          <span className="text-xs text-red-500">{errors.desc?.message}</span>

          <label htmlFor="visit" className="block mt-3 mb-1 text-sm text-gray-400">
            Visit
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="visit"
            id="visit"
            {...register('visit')}
          />
          <span className="text-xs text-red-500">{errors.visit?.message}</span>

          <p className="mt-6 mb-1 font-semibold text-lg">
            Social Media <span className="text-gray-400 text-base font-normal">(Minimal 2)</span>
          </p>

          <label htmlFor="instagram" className="block mt-3 mb-1 text-sm text-gray-400">
            instagram
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="instagram"
            id="instagram"
            placeholder="instagram.com/"
            {...register('instagram')}
          />
          <span className="text-xs text-red-500">{errors.instagram?.message}</span>

          <label htmlFor="facebook" className="block mt-3 mb-1 text-sm text-gray-400">
            facebook
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="facebook"
            id="facebook"
            placeholder="facebook.com/"
            {...register('facebook')}
          />
          <span className="text-xs text-red-500">{errors.facebook?.message}</span>

          <label htmlFor="linkedin" className="block mt-3 mb-1 text-sm text-gray-400">
            Linkedin
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="linkedin"
            id="linkedin"
            placeholder="linkedin.com/"
            {...register('linkedin')}
          />

          <label htmlFor="twitter" className="block mt-3 mb-1 text-sm text-gray-400">
            twitter
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="twitter"
            id="twitter"
            placeholder="twitter.com/"
            {...register('twitter')}
          />

          <button className="inline-block mt-8 px-6 h-[50px] bg-[#5331CE] text-white rounded-full">
            Simpan
          </button>
        </form>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
