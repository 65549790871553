import Button from "../Form/Button";

const ErrorComponent = () => {
  return (
    <main className="container pt-28 min-h-screen w-full">
      <div className="text-center pt-24">
        <h1 className="text-2xl font-bold text-gray-400 mb-5">
          Terjadi kesalahan, silahkan kembali lagi nanti
        </h1>
        <Button small link="/" className="inline-block">
          Kembali
        </Button>
      </div>
    </main>
  );
};
export default ErrorComponent;
