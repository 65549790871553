import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import IMG from '../assets/images/CoverContactUs.svg'

export default function Contactus() {

  const valid = yup.object().shape({
    nama: yup.string().required('Nama harus di isi!'),
    email: yup.string().email().required('Email harus di isi!'),
    msg: yup.string().required('pesan harus di isi!'),
  })

  const { register, handleSubmit, formState: {errors} } = useForm({
    resolver: yupResolver(valid)
  })

  const callBackSubmit =  data => {
    console.log(data)
  }

  return ( 
  <section className='mt-36 mb-10 max-w-7xl mx-auto px-12 grid grid-cols-1 gap-5'>
    <h1 className='font-bold text-4xl text-[#5331ce]'>Contact Us</h1>
    <p className='text-main-darkest'>Ada pertanyaan? Silahkan hubungi kami!</p>
    <div className='grid grid-cols-1 md:grid-cols-2'>
      <form onSubmit={handleSubmit(callBackSubmit)} className='flex flex-col gap-5'>
        <div className={parentInput}>
          <label className={label} htmlFor="nama">Nama Lengkap</label>
          <input className={input} type="text" name="nama" { ...register('nama')} />
          <span className={span}>{errors.nama?.message}</span>
        </div>

        <div className={parentInput}>
          <label className={label} htmlFor="email">E-mail</label>
          <input className={input} type="email" name="email" { ...register('email')} />
          <span className={span}>{errors.email?.message}</span>
        </div>

        <div className={parentInput}>
          <label className={label} htmlFor="msg">Pesan atau pertanyaan</label>
          <textarea placeholder='Saya ingin' className={input} name='msg' { ...register('msg')} ></textarea>
          <span className={span}>{errors.msg?.message}</span>
        </div>

        <button className='self-start text-[#fff] hover:text-[#5331ce] bg-[#5331ce] font-bold outline-none hover:bg-transparent border-2 border-[#5331ce] rounded-full p-2 w-36 mt-5' type="submit">Kirim</button>
      </form>

      <div className='hidden md:flex h-[550px]'>
        <img src={IMG} alt="cover" />
      </div>
    </div>
  </section>
  )
}

const parentInput = 'mb-3 md:mb-0 flex flex-col text-main-darkest'
const label = 'font-medium mb-1 md:mb-0'
const input = 'w-ful h-12 rounded-lg p-1 outline-none border-2 border-main-color'
const span = 'text-red-500 text-xs mt-1'
