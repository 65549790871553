import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Sidebar from '../../Components/Layouts/Profile/Sidebar';
import Input from '../../Components/Form/Input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import { getJwtFromLocal } from '../../utils/getJwt';

export default function ProfileBank() {
  const { username } = useParams();

  const valid = yup.object().shape({
    bank_name: yup.string().required(),
    bank_number: yup.string().required().min(10, 'Rekening Bank must be at least 10 character'),
    name: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(valid),
  });

  const handleData = data => {
    const { isLocal, jwt } = getJwtFromLocal();
    if (!isLocal) {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');

    let raw = JSON.stringify({
      bank_name: data.bank_name,
      account_id: data.bank_number,
      name: data.name,
    });

    const req = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const postBank = async () => {
      const api = await fetch(`${process.env.REACT_APP_API_URL}/banks`, req);
      if (api.status === 500) {
        error500();
      } else {
        const json = await api.json();
        succes();
      }
    };

    postBank();
    console.log(data);
  };

  const succes = () => {
    toast.success('Success Create Account Bank', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  const error500 = () => {
    toast.error('You already Submited Account Bank!', {
      position: 'top-center',
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <div className="container mx-auto pt-28 flex gap-[80px] mb-20">
      <Sidebar activeLink={1} username={username} isTripLeader={false} />
      <div className="w-[633px] max-w-full">
        <p className="text-[28px] text-main-color">Data Rekening Bank</p>
        <p className="text-[#444444] text-sm mt-3.5">
          <span className="text-red-600">*</span> Mohon untuk mengisi data secara benar
        </p>
        <p className="mt-3 mb-1 font-semibold text-lg">Rekening Utama Anda</p>
        <form className="grid" onSubmit={handleSubmit(handleData)}>
          <label htmlFor="bank_name" className="block mt-3 mb-1 text-sm text-gray-400">
            Nama bank
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="bank_name"
            id="bank_name"
            {...register('bank_name')}
          />
          <span className="text-xs text-red-500">{errors.bank_name?.message}</span>

          <label htmlFor="bank_number" className="block mt-3 mb-1 text-sm text-gray-400">
            Rekening Bank
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="bank_number"
            id="bank_number"
            placeholder="Masukan nomor rekening bank anda disini"
            {...register('bank_number')}
          />
          <span className="text-xs text-red-500">{errors.bank_number?.message}</span>

          <label htmlFor="name" className="block mt-3 mb-1 text-sm text-gray-400">
            Rekening Atas Nama
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="text"
            name="name"
            id="name"
            placeholder="Masukan nama rekening anda disini"
            {...register('name')}
          />
          <span className="text-xs text-red-500">{errors.name?.message}</span>

          <button className="inline-block mt-8 px-6 h-[50px] w-36 bg-[#5331CE] text-white rounded-full">
            Simpan
          </button>
        </form>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
