import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from "../../Components/Layouts/Profile/Sidebar";
import iconPayout from '../../assets/icons/payout.png'
import iconPayment from '../../assets/icons/payment.png'
import { getJwtFromLocal } from '../../utils/getJwt'

export default function ProfilePayment() {
  const [selected, setSelected] = useState('')
  const [time, setTime] = useState('')
  const [saldo, setSaldo] = useState([])
  const { username } = useParams()

  const getSaldo = async () => {
    const { isLocal, jwt } = getJwtFromLocal()
    if (!isLocal) {
      return
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${jwt}`)
    const req = { method: 'GET', headers: myHeaders, redirect: 'follow' };
    const api = await fetch(`${process.env.REACT_APP_API_URL}/profile`, req)
    const json = await api.json()
    setSaldo(json.data)
  }

  useEffect(() => {
    getSaldo()
  }, [])

  return (
    <div className="container mx-auto pt-28 flex gap-[80px] mb-20">
      <Sidebar activeLink={2} username={username} isTripLeader={false} />
      <div className="w-[633px] max-w-full">
        <p className="text-[28px] text-main-color">Pembayaran dan Pencairan</p>
        <p className="text-[#444444] text-sm mt-3.5">
          Anda dapat melihat riwayat pembayaran dan pencairan dana maupun menambahkan saldo anda melalui halaman in
        </p>

        <div className='flex justify-between items-center mt-4'>
          <div>
            <p className='mb-1'>Saldo Anda</p>
            <p className='text-2xl text-[#372189] font-medium'>Rp. {saldo?.balance}</p>
          </div>
          <button className='px-6 h-[50px] border border-[#5331CE] text-[#5331CE] rounded-full'>Cairkan dana</button>
        </div>
        
        <label className='block font-medium mt-14 mb-1' htmlFor='rekening'>Pilih Rekening Utama Anda</label>
        <select id='rekening' value={selected} onChange={e => setSelected(e.target.value)} className='w-full form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50'>
          <option value='bni'>BNI</option>
          <option value='bca'>BCA</option>
        </select>

        <div className='flex flex-col gap-10 mt-14'>
          <div className='flex justify-between items-center'>
            <div className='flex-1'>
              <p>Riwayat Pembayaran & Pencairan Anda</p>
            </div>
            <div className='flex-1 flex justify-end'>
              <select id='time' value={time} onChange={e => setTime(e.target.value)} className='w-40 form-input px-4 py-3 mt-2 rounded-full border border-gray-400 text-gray-800'>
                <option value='02'>Februari 2023</option>
                <option value='03'>Maret 2023</option>
                <option value='04'>April 2023</option>
              </select>
            </div>
          </div>

          <div>
            {data.map((data, index) => 
              <div key={index} className='p-6 border-b border-gray-400'>
                {data.type === 'payment' ? (
                  <div className='w-[132px] mb-3 text-xs px-2 py-3 rounded bg-[#E8F6ED] text-[#149443] flex gap-x-2'>
                    <img src={iconPayment} className='object-fit' alt='payment icon'/>
                    <p>Pengisian Saldo</p>
                  </div>
                ) : (
                  <div className='w-[132px] mb-3 text-xs px-2 py-3 rounded bg-[#FDECEC] text-[#D93E3E] flex gap-x-2'>
                    <img src={iconPayout} className='object-fit' alt='payment icon'/>
                    <p>Pencairan Dana</p>
                  </div>
                )}
                <div className='flex justify-between items-center gap-10'>
                  <div className='flex-1 w-1/2'>
                    <p className='text-sm'>{data.bank} - {data.rek} A.N {data.name}</p>
                    <p className='text-[#999999]'>{data.date} | {data.time} WIB</p>
                  </div>
                  <div className='flex-1 w-1/2 flex justify-between'>
                      <p className='text-[#333333] font-medium'>Rp {data.sum}</p>
                      {data.status === 200 ? (
                        <div className='h-[28px] flex items-center px-3 rounded-full bg-[#E8F6ED] text-[#149443] text-[10px] font-medium'>
                          Berhasil
                        </div>
                      ): (
                        <div className='h-[28px] flex items-center px-3 rounded-full bg-[#FDECEC] text-[#D93E3E] text-[10px] font-medium'>
                          Gagal
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>        
        </div>
      </div>
    </div>
  )
}

const data = [
  {
    type: 'payment',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 200,
    date:'12/02/2023',
    time: '14.30',
  },
  {
    type: 'payment',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 400,
    date:'12/02/2023',
    time: '14.30',
  },
  {
    type: 'payout',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 200,
    date:'12/02/2023',
    time: '14.30',
  },
  {
    type: 'payment',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 400,
    date:'12/02/2023',
    time: '14.30',
  },
  {
    type: 'payment',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 200,
    date:'12/02/2023',
    time: '14.30',
  },
  {
    type: 'payment',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 400,
    date:'12/02/2023',
    time: '14.30',
  },
  {
    type: 'payout',
    bank: 'BNI',
    rek: 2103439940,
    name: 'Tatiana Sejahtera Travel',
    sum: 2000000,
    status: 200,
    date:'12/02/2023',
    time: '14.30',
  },
]
