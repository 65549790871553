import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Sidebar from '../../Components/Layouts/Profile/Sidebar';
import Input from '../../Components/Form/Input';
import Avatar, { genConfig } from 'react-nice-avatar';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import { getJwtFromLocal } from '../../utils/getJwt';

export default function ProfileSetting() {
  const [src, setSrc] = useState(null);
  const { username } = useParams();
  const { register, handleSubmit } = useForm();
  const avatarConfig = genConfig(username);

  const handleData = data => {
    const { isLocal, jwt } = getJwtFromLocal();

    if (!isLocal) {
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify({
      current_password: data.current_password,
      new_password: data.password,
    });
    let req = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const postNewPw = async () => {
      const api = await fetch(`${process.env.REACT_APP_API_URL}/users/password`, req);
      if (api.status === 400) {
        return error400();
      } else {
        const json = await api.json();
        console.log(json);
        succes();
      }
    };
    postNewPw();
  };

  const succes = () => {
    toast.success('Succes Change Password!', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  const error400 = () => {
    toast.error('Failed. Password Lama Salah!', {
      position: 'top-center',
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  return (
    <div className="container mx-auto pt-28 flex gap-[80px] mb-20">
      <Sidebar activeLink={3} username={username} isTripLeader={false} />
      <div className="w-[633px] max-w-full">
        <p className="text-[28px] text-main-color">Pengaturan</p>
        <p className="text-[#444444] text-sm mt-3.5">
          <span className="text-red-600">*</span> Mohon untuk mengisi data secara benar
        </p>
        <form onSubmit={handleSubmit(handleData)}>
          <div className="flex gap-6 items-center">
            <div className="w-[100px] h-[100px] rounded-full overflow-hidden">
              {src !== null ? (
                <img src={src} alt="profile" />
              ) : (
                <div className="avatar rounded-full">
                  <Avatar className="w-[100px] h-[100px] rounded-full" {...avatarConfig} />
                </div>
              )}
            </div>
            <div>
              <label htmlFor="image" className="block mt-3 mb-1">
                Ubah Photo Profile <span className="text-sm text-gray-400">(max 1mb)</span>
              </label>
              <Input
                className="w-full"
                inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                type="file"
                name="image"
                id="image"
                {...register('image', {
                  onChange: e => setSrc(URL.createObjectURL(e.target.files[0])),
                })}
              />
            </div>
          </div>

          <label htmlFor="current_password" className="block mt-3 mb-1 text-sm text-gray-400">
            Kata Sandi Lama Anda
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="current_password"
            name="current_password"
            id="current_password"
            required
            placeholder=""
            {...register('current_password')}
          />

          <label htmlFor="password" className="block mt-3 mb-1 text-sm text-gray-400">
            Ubah Kata Sandi
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="password"
            name="password"
            id="password"
            required
            placeholder=""
            {...register('password')}
          />
          <button className="inline-block mt-8 px-6 h-[50px] border border-[#5331CE] text-[#5331CE] rounded-full">
            Ubah kata Sandi
          </button>
        </form>
      </div>
    </div>
  );
}
