import axios from 'axios'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { getJwtFromLocal } from '../../utils/getJwt'
import priceFormat from '../../utils/priceFormat'
import { useNavigate } from 'react-router-dom'
import { AiOutlineSearch } from 'react-icons/ai'
import { toast } from 'react-toastify'

const fetchPayments = async () => {
  const { isLocal, jwt } = getJwtFromLocal('ADMIN')

  if (!isLocal) {
    return
  }

  const headers = {
    Authorization: `Bearer ${jwt}`,
  }

  const res = await axios(`${process.env.REACT_APP_API_URL}/admin/payments`, {
    headers,
  })
  return res
}

export default function PaymentAdmin() {
  const { data, status } = useQuery('payments', fetchPayments)
  const navigate = useNavigate()

  const openDetail = (id) => navigate(`${id}`)

  useEffect(() => {
    if (status === 'error') {
      toast.error('Gagal terkoneksi', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }, [status])

  if (status === 'loading') {
    return (
      <>
        <p className='text-xl text-[#303030] mb-8'>Payment</p>
        <div className='h-fit px-4 bg-white rounded'>
          <div className='py-4 px-5 flex justify-between'>
            <div className='flex items-center gap-2'>
              <input
                className='p-1 w-14 border border-[#CCCCCC] rounded outline-none'
                type='number'
              />
              <p className='text-[#212529] text-sm'>entries per page</p>
            </div>
            <div className='flex items-center gap-2'>
              <p className='text-[#7A7A7A]'>Search:</p>
              <div className='relative h-fit'>
                <input className='p-2 rounded outline-none border border-[#CCCCCC] max-w-40' />
                <div className='absolute left-2 top-1/2 -translate-y-1/2'>
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead>
              <tr className='text-[#111111]'>
                <th className='py-4'>Title</th>
                <th className='py-4'>Status</th>
                <th className='py-4'>Qty</th>
                <th className='py-4 pr-12 text-right'>Total amount</th>
                <th className='py-4'>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan='5'>
                  <p>Loading ...</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  if (status === 'error') {
    return (
      <>
        <p className='text-xl text-[#303030] mb-8'>Payment</p>
        <div className='h-48 px-4 bg-white rounded'>
          <div className='py-4 px-5 flex justify-between'>
            <div className='flex items-center gap-2'>
              <input
                className='p-1 w-14 border border-[#CCCCCC] rounded outline-none'
                type='number'
              />
              <p className='text-[#212529] text-sm'>entries per page</p>
            </div>
            <div className='flex items-center gap-2'>
              <p className='text-[#7A7A7A]'>Search:</p>
              <div className='relative h-fit'>
                <input className='p-2 rounded outline-none border border-[#CCCCCC] max-w-40' />
                <div className='absolute left-2 top-1/2 -translate-y-1/2'>
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead>
              <tr className='text-[#111111]'>
                <th className='py-4 text-left'>Title</th>
                <th className='py-4'>Status</th>
                <th className='py-4'>Qty</th>
                <th className='py-4 pr-12 text-right'>Total amount</th>
                <th className='py-4'>Action</th>
              </tr>
            </thead>
          </table>
        </div>
      </>
    )
  }

  if (status === 'success') {
    return (
      <>
        <p className='text-xl text-[#303030] mb-8'>Payment</p>
        <div className='h-fit px-4 pb-6 bg-white rounded'>
          <div className='py-4 px-5 flex justify-between'>
            <div className='flex items-center gap-2'>
              <input
                className='p-1 w-14 border border-[#CCCCCC] rounded outline-none'
                type='number'
              />
              <p className='text-[#212529] text-sm'>entries per page</p>
            </div>
            <div className='flex items-center gap-2'>
              <p className='text-[#7A7A7A]'>Search:</p>
              <div className='relative h-fit'>
                <input className='p-2 rounded outline-none border border-[#CCCCCC] max-w-40' />
                <div className='absolute left-2 top-1/2 -translate-y-1/2'>
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <table className='w-full'>
            <thead>
              <tr className='text-[#111111]'>
                <th className='py-4'>Title</th>
                <th className='py-4'>Status</th>
                <th className='py-4'>Qty</th>
                <th className='py-4 pr-12 text-right'>Total amount</th>
                <th className='py-4'>Action</th>
              </tr>
            </thead>
            <tbody>
              <TablePayment
                dataPayment={data.data.data}
                handleOpen={openDetail}
              />
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

// table
const TablePayment = ({ dataPayment, handleOpen }) => {
  return (
    <>
      {dataPayment.map(
        ({ order_id, total_amount, status, quantity, title, slug }) => (
          <tr key={order_id}>
            <td className='py-2 pt-3'>{title}</td>
            <td className='py-2 pt-3'>
              <div className='block w-full flex justify-center'>
                <span
                  className={
                    status === 'paid'
                      ? 'rounded-full px-6 py-1 bg-emerald-200 text-emerald-600 capitalize'
                      : 'rounded-full px-6 py-1 bg-red-200 text-red-600 capitalize'
                  }
                >
                  {status}
                </span>
              </div>
            </td>
            <td className='py-2 pt-3 text-center'>{quantity}</td>
            <td className='py-2 pt-3 pr-12'>
              <p className='text-right'>{priceFormat(total_amount)}</p>
            </td>
            <td className='py-2 pt-3'>
              <button
                onClick={() => handleOpen(order_id)}
                className='py-3 h-10 flex items-center justify-center rounded w-full border border-[#5331CE] text-[#5331CE] hover:bg-[#5331CE] hover:text-white'
              >
                Detail
              </button>
            </td>
          </tr>
        )
      )}
    </>
  )
}
