import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  IoBonfireOutline,
  IoPersonCircleOutline,
  IoLogOutOutline,
  IoMapOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Dropdown({
  children,
  buttonClassName,
  title,
  containerClassName,
  username,
  handleLogout,
  isTripLeader = false,
}) {
  return (
    <div className={`fixed ${containerClassName}`}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className={buttonClassName} title={title}>
            {children}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/profile/${username}/account`}
                    className={`${
                      active ? "bg-main-color text-white" : "text-gray-900"
                    } group flex w-full items-center rounded px-3 py-3 text-sm font-medium normal-case`}
                  >
                    <IoPersonCircleOutline
                      size={20}
                      className="mr-2.5 h-[20px] w-[20px]"
                      aria-hidden="true"
                      color={active ? "#ffffff" : "#6750A4"}
                      strokeWidth={24}
                    />
                    {username || "Profil"}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={`${
                      active ? "bg-main-color text-white" : "text-gray-900"
                    } group flex w-full items-center rounded px-3 py-3 text-sm font-medium`}
                    to={`/profile/${username}/trips`}
                  >
                    <IoMapOutline
                      size={20}
                      className="mr-2.5 h-[20px] w-[20px]"
                      aria-hidden="true"
                      color={active ? "#ffffff" : "#6750A4"}
                      strokeWidth={24}
                    />
                    Perjalanan Saya
                  </Link>
                )}
              </Menu.Item>
              {!isTripLeader && (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/profile/${username}/verify`}
                      className={`${
                        active ? "bg-main-color text-white" : "text-gray-900"
                      } group flex w-full items-center rounded px-3 py-3 text-sm font-medium normal-case`}
                    >
                      <IoBonfireOutline
                        size={20}
                        className="mr-2.5 h-[20px] w-[20px]"
                        aria-hidden="true"
                        color={active ? "#ffffff" : "#6750A4"}
                        strokeWidth={24}
                      />
                      Jadi Pemandu
                    </Link>
                  )}
                </Menu.Item>
              )}
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-main-color text-white" : "text-gray-900"
                    } group flex w-full items-center rounded px-3 py-3 text-sm font-medium`}
                    onClick={handleLogout}
                  >
                    <IoLogOutOutline
                      size={20}
                      className="mr-2.5 h-[20px] w-[20px]"
                      aria-hidden="true"
                      color={active ? "#ffffff" : "rgb(220 38 38)"}
                      strokeWidth={24}
                    />
                    Keluar
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
