import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: '',
  user_id: null,
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    login: (state, action) => {
      state.username = action.payload.username
      state.user_id = action.payload.user_id
    },
    logout: (state) => {
      state.username = ''
      state.user_id = null
    },
  },
})

export const { login, logout } = adminSlice.actions

export default adminSlice.reducer
