import React, { useEffect } from 'react';
import Stepper from './Stepper';
import { useForm } from 'react-hook-form';
import Input from '../Form/Input';
import image from '../../assets/trip.png';
import * as yup from 'yup';

const schema = yup.object().shape({
  akomodasi: yup.string().required(),
  transportasi: yup.string().required(),
  'tiket-wisata': yup.string().required(),
  'biaya-tak-terduga': yup.string().required(),
  'fee-tripleader': yup.string().required(),
});

export default function FormCostBreakdown({ src, handleStep }) {
  const { register, handleSubmit, watch } = useForm({});

  const dependent = watch([
    'akomodasi',
    'transportasi',
    'tiket-wisata',
    'biaya-tak-terduga',
    'fee-tripleader',
  ]);

  const fee = dependent.reduce((acc, curr = 0) => acc + Number(curr), 0);
  const feeTemanTrip = (fee * 10) / 100;
  const feeTotal = [...dependent, feeTemanTrip].reduce((acc, curr = 0) => acc + Number(curr), 0);

  const handleCost = (data = {}) => {
    const merged = {
      ...data,
      'fee-temantrip': String(feeTemanTrip),
    };
    console.log({ merged });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto h-fit pt-32 mb-40 px-4 md:px-0 flex gap-12 flex-col md:flex-row justify-between">
      <div className="w-[633px] max-w-full h-fit">
        <p className="text-[28px] mb-8">Lengkapi Data Biaya Trip Anda</p>
        <Stepper steps={1} />
        <form onSubmit={handleSubmit(handleCost)}>
          <label htmlFor="akomodasi" className="block mt-6 text-[14px] text-gray-400">
            Akomodasi
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="akomodasi"
            id="akomodasi"
            placeholder="Masukkan Nomonial harga"
            {...register('akomodasi')}
          />

          <label htmlFor="transportasi" className="block mt-6 text-[14px] text-gray-400">
            Transportasi
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="transportasi"
            id="transportasi"
            placeholder="Masukkan Nomonial harga"
            {...register('transportasi')}
          />

          <label htmlFor="tiket-wisata" className="block mt-6 text-[14px] text-gray-400">
            Tiket-wisata
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="tiket-wisata"
            id="tiket-wisata"
            placeholder="Masukkan Nomonial harga"
            {...register('tiket-wisata')}
          />

          <label htmlFor="biaya-tak-terduga" className="block mt-6 text-[14px] text-gray-400">
            Biaya tak terduga
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="biaya-tak-terduga"
            id="biaya-tak-terduga"
            placeholder="Masukkan Nomonial harga"
            {...register('biaya-tak-terduga')}
          />

          <label htmlFor="fee-tripleader" className="block mt-6 text-[14px] text-gray-400">
            Fee Tripleader
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="fee-tripleader"
            id="fee-tripleader"
            placeholder="Masukkan Nomonial harga"
            {...register('fee-tripleader')}
          />

          <label htmlFor="fee-temantrip" className="block mt-6 text-[14px] text-gray-400">
            Fee temantrip (10%)
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="number"
            name="fee-temantrip"
            id="fee-temantrip"
            placeholder="Masukkan Nomonial harga"
            disabled
            value={feeTemanTrip}
          />
        </form>
        <div className="flex justify-between mt-[12px] font-medium">
          <p>Total</p>
          <p>Rp {new Intl.NumberFormat('id').format(feeTotal)}</p>
        </div>
        <div className="flex justify-between mt-[12px] font-medium gap-[24px]">
          <button
            onClick={() => handleStep(prev => prev - 1)}
            className="flex-1 rounded-full px-3 py-6 border border-[#5331CE] text-[#5331CE]">
            Kembali
          </button>
          <button
            onClick={() => handleStep(prev => prev + 1)}
            className="flex-2 rounded-full px-3 py-6 border border-[#5331CE] bg-[#5331CE] text-white w-[502px]">
            Selanjutnya
          </button>
        </div>
      </div>
      <div className="w-[486px] max-w-full h-[800px] bg-gray-200 rounded-lg relative hidden md:block">
        <img
          src={image}
          className="w-full h-full object-cover object-center rounded-lg"
          alt="cover trip"
        />
      </div>
    </div>
  );
}
