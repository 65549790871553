import React from 'react'
import Avatar, { genConfig } from 'react-nice-avatar'
import { Link } from 'react-router-dom'

export default function Sidebar({ activeLink, username, isTripLeader }) {
  const avatarConfig = genConfig(username)

  return (
    <div className='w-[200px] relative h-fit relative'>
      <div className='w-[1px] h-full bg-gray-200 absolute top-0 -right-6'></div>
      <p className='mb-16 text-[28px]'>Akun</p>
      <div className='avatar rounded-full mb-[12px]'>
        <Avatar className='w-[200px] h-[200px]' {...avatarConfig} />
      </div>
      <div className='mx-auto text-center'>
        <p className='text-xs'>
          Status: <span className='text-main-color'>Trip member</span>
        </p>
        {!isTripLeader ? (
          <button className='h-9 px-4 rounded-full bg-[#DDD6F5] text-[#5331CE] mt-[12px] font-medium flex items-center mx-auto'>
            <Link to={`/profile/${username}/verify`}>
            Upgrade ke Trip Leader
            </Link>
          </button>
        ) : null}
        <p className='mt-[12px] text-lg text-[#372189]'>{username}</p>
        <p className='mt-1 text-xs text-[#666666s]'>Indonesia</p>
      </div>
      <div className='flex flex-col gap-[20px] mt-[40px] text-base'>
        {menus.map((menu) => (
          <Link
            key={menu.id}
            to={`/profile/${username}/${menu.path}`}
            className={menu.id === activeLink ? 'text-main-color font-medium' : null}
          >
            {menu.name}
          </Link>
        ))}
      </div>
    </div>
  )
}

const menus = [
  {
    id: 0,
    name: 'Akun',
    path: 'account',
  },
  {
    id: 1,
    name: 'Data Rekening Bank',
    path: 'bank',
  },
  {
    id: 2,
    name: 'Pembayaran & Pencairan',
    path: 'payment',
  },
  {
    id: 3,
    name: 'Pengaturan',
    path: 'setting',
  },
]
