import { useRef } from "react";
import destinations from "../assets/sample_data/destinations.json";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { motion } from "framer-motion";

const Carousel = ({ className, style }) => {
  const carouselRef = useRef();

  const handleScroll = (offset) => {
    carouselRef.current.scrollLeft += offset;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 100 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
      className={`${className} container flex carousel-container w-full`}
      style={style}
    >
      <div className="relative">
        <button
          className="p-1.5 border-2 rounded-full border-transparent hover:transition-all hover:text-white hover:border-main-color hover:bg-main-color active:bg-main-dark z-10 opacity-50 hover:opacity-100 absolute -left-8 md:-left-10 xl:-left-11 2xl:left-24"
          style={{ top: "50%", transform: "translate(0, -50%" }}
          onClick={() => handleScroll(-387.38)}
        >
          <AiOutlineLeft
            title="previous"
            style={{ strokeWidth: 64 }}
            size={18}
          />
        </button>
      </div>
      <motion.div
        ref={carouselRef}
        className="carousel-wrapper overflow-x-auto flex w-[336px] md:w-[723.38px] xl:w-full 2xl:w-[81.25%] mx-auto hide-scrollbar scroll-smooth"
      >
        <div className="gap-11 lg:gap-[3.67rem] mb-12 flex">
          {destinations.map((destination) => (
            <motion.div
              initial={{ x: 400, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -400, opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.15 }}
              key={destination.name}
              className={`destination-wrapper shrink-0 basis-96 bg-white rounded`}
            >
              <img
                src={destination.img}
                alt={destination.name}
                className="object-top object-cover w-full h-64 rounded-t"
              />
              <div className="py-8 px-10 text-center">
                <h2 className="uppercase font-semibold text-center mb-5">
                  {destination.title}
                </h2>
                <p className="text-center font-light leading-6 mb-7">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maxime ad dolore esse, praesentium, quae quidem facere.
                </p>
                <button className="border-main-color border-2 text-sm text-main-color font-semibold py-3.5 px-8 hover:bg-main-color hover:text-white rounded hover:transition-all active:bg-main-dark">
                  SELENGKAPNYA
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
      <div className="relative">
        <button
          className="p-1.5 border-2 rounded-full border-transparent hover:transition-all hover:text-white hover:border-main-color hover:bg-main-color active:bg-main-dark z-10 opacity-50 hover:opacity-100 absolute -left-2 md:left-0 xl:-left-3 2xl:-left-36"
          style={{ top: "50%", transform: "translate(0, -50%" }}
          onClick={() => handleScroll(387.38)}
        >
          <AiOutlineRight title="next" style={{ strokeWidth: 64 }} size={18} />
        </button>
      </div>
    </motion.div>
  );
};

export default Carousel;
