import { Link }  from 'react-router-dom'
import Coin from '../../assets/images/coin.png'
import Payout from '../../Pages/PaymentPayout'
import { useParams } from 'react-router-dom'

export default function Diterima() {
  const { slug } =  useParams()

  return <Payout>
  <div className={div}>
    <h1 className='text-main-color'>Saldo Anda Rp 60.000.000</h1>
    <div className={div2}>
      <div className='text-main-color text-sm'>
        <p>Isi data bank anda verifikasi data diri anda agar dana bisa dicairkan</p>
      </div>

      <div className='w-40 h-20'>
        <img src={Coin} alt="Coin" />
      </div>

      <div className='col-span-2'>
        <button className={btn}><Link to={`/payout/${slug}/dicairkan`}>Selesaikan setting pembayaran untuk pencairan dana</Link></button>
      </div>
    </div>
  </div>
  </Payout>
}
const div = 'mt-10 col-span-2 mb-20 w-96'
const div2 = 'mt-5 mb-20 rounded bg-[#e5e0f7] grid grid-cols-2 gap-5 place-items-center p-2'
const btn = 'rounded bg-white text-main-darkest text-sm p-1'
