import { useState } from 'react';
import Button from '../Components/Form/Button';
import Input from '../Components/Form/Input';
import { motion } from 'framer-motion';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getJwtFromLocal } from '../utils/getJwt';
import SrcImg from '../assets/ktp.png';

const TripLeaderVerify = () => {
  const [stepCounter, setStepCounter] = useState(1);
  const [srcImg, setSrcImg] = useState();

  //form validator
  const verifySchema = yup.object({
    nik: yup
      .string()
      .max({ limit: 16, message: 'NIK harus berisikan 16 karakter' })
      .min({ limit: 16, message: 'NIK harus berisikan 16 karakter' })
      .required(),
    no_hp: yup.string().min({ limit: 10, message: 'No Handphone Minimal 10 karakter' }).required(),
    address: yup.string().required(),
    image: yup.mixed().test('required', 'Ktp is required', value => {
      return value && value.length;
    }),
    facebook: yup.string().required(),
    instagram: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(verifySchema) });

  const nextStep = (next = true) => {
    const stepDirection = next ? 1 : -1; // +1 next step / -1 prev step

    if (stepCounter >= 1 && stepCounter <= 3) {
      setStepCounter(stepCounter + stepDirection);
    }
  };

  const handleVerifySubmit = async data => {
    const { isLocal, jwt } = getJwtFromLocal();

    if (!isLocal) {
      return;
    }

    const body = {
      nik: data.nik,
      no_hp: data.no_hp,
      address: data.address,
      image: data.image[0],
      linkedin: data.linkedin,
      twitter: data.twitter,
      facebook: data.facebook,
      instagram: data.instagram,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/profile/verification`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(res => {
        if (res.status === 201) {
          toast.success('berhasil dikirim', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const Step1 = () => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15, ease: 'easeInOut' }}>
        <div className="text-xl font-bold text-gray-500 mb-10">
          <h1>Masukkan data diri anda</h1>
        </div>
        <div className="data-form-wrapper flex flex-col gap-6 text-gray-500">
          <div className="flex items-center gap-2">
            <label htmlFor="nik" className="font-medium min-w-[10rem]">
              Nomor Induk (NIK)
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="648548745215XXXX"
              className="grow mb-0"
              name="nik"
              max="16"
              min="16"
              {...register('nik')}
              required
              errors={errors?.nik?.message}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="tel" className="font-medium min-w-[10rem]">
              Nomor Telepon
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="085122334XXX"
              className="grow mb-0"
              name="no_hp"
              {...register('no_hp')}
              required
              errors={errors?.no_hp?.message}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="address" className="font-medium min-w-[10rem]">
              Alamat
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="Jl. Kaliurang No. 34B Umbulmartani, Sleman"
              className="grow mb-0"
              name="address"
              {...register('address')}
              required
              errors={errors?.address?.message}
            />
          </div>
        </div>
      </motion.div>
    );
  };
  const Step2 = () => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15, ease: 'easeInOut' }}
        className="h-full flex flex-col">
        <div className="text-xl font-bold text-gray-500 mb-10">
          <h1>Unggah KTP anda</h1>
        </div>
        <input
          type="file"
          className="w-full mb-12"
          name="image"
          {...register('image', {
            onChange: e => setSrcImg(URL.createObjectURL(e.target.files[0])),
          })}
        />
        <div className="w-full min-h-24 relative mb-12">
          <div className="h-full aspect-[9/5] rounded-lg overflow-hidden mx-auto">
            <img src={SrcImg} className="object-fill" alt="ktp" />
          </div>
        </div>
      </motion.div>
    );
  };
  const Step3 = () => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15, ease: 'easeInOut' }}>
        <div className="text-xl font-bold text-gray-500 mb-10">
          <h1>Cantumkan sosial media anda</h1>
        </div>
        <div className="data-form-wrapper flex flex-col gap-6 text-gray-500">
          <div className="flex items-center gap-2">
            <label htmlFor="Facebook" className="font-medium min-w-[10rem]">
              Facebook
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="https://facebook.com/"
              className="grow mb-0"
              name="facebook"
              {...register('facebook')}
              errors={errors?.facebook?.message}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="Instagram" className="font-medium min-w-[10rem]">
              Instagram
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="https://instagram/"
              className="grow mb-0"
              name="instagram"
              {...register('instagram')}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="LinkedIn" className="font-medium min-w-[10rem]">
              LinkedIn
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="https://www.linkedin.com/in/"
              className="grow mb-0"
              name="linkedin"
              {...register('linkedin')}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="Twitter" className="font-medium min-w-[10rem]">
              Twitter
            </label>
            <span className="font-bold">:</span>
            <Input
              placeholder="https://twitter.com/"
              className="grow mb-0"
              name="twitter"
              {...register('twitter')}
            />
          </div>
        </div>
      </motion.div>
    );
  };

  const formWrapper = steps => {
    switch (steps) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      default:
        return;
    }
  };

  return (
    <main className="pt-28 container min-h-screen">
      <div className="text-center mt-12">
        <h1 className="text-2xl font-bold text-gray-600 mb-1">Silahkan masukkan data diri anda</h1>
        <p className="font-light text-gray-500">
          Kami akan meninjau data & profil anda untuk menjadi pemimpin/pemandu perjalanan
        </p>
      </div>
      <div className="verify-form-wrapper border mt-16 rounded mb-24 min-h-[500px] max-w-[64rem] mx-auto flex min-w-fit">
        <form
          className="my-14 ml-14 mr-20 grow flex flex-col justify-between"
          onSubmit={handleSubmit(data => handleVerifySubmit(data))}>
          <div className="form-wrapper grow">{formWrapper(stepCounter)}</div>
          <div className="control-buttons w-full">
            <div className="buttons-wrapper grid grid-cols-3 gap-2 items-center justify-items-center">
              {stepCounter > 1 ? (
                <Button
                  secondary
                  small
                  onClick={() => nextStep(false)}
                  className="w-full"
                  type="button">
                  Kembali
                </Button>
              ) : (
                <div />
              )}

              <div id="stepper" className="flex gap-3">
                <div
                  className={`transition-all ease-in-out w-3 h-3 rounded-full border-2 border-main-color ${
                    stepCounter > 0 && 'bg-main-color'
                  }`}
                />
                <div
                  className={`transition-all ease-in-out w-3 h-3 rounded-full border-2 border-main-color ${
                    stepCounter > 1 && 'bg-main-color'
                  }`}
                />
                <div
                  className={`transition-all ease-in-out w-3 h-3 rounded-full border-2 border-main-color ${
                    stepCounter > 2 && 'bg-main-color'
                  }`}
                />
              </div>

              {stepCounter !== 3 ? (
                <Button small onClick={() => nextStep(true)} type="button" className="w-full">
                  Selanjutnya
                </Button>
              ) : (
                <Button small className="w-full" type="submit">
                  Kirim
                </Button>
              )}
            </div>
          </div>
        </form>
        <div
          className="bg-red-100 basis-1/3 bg-cover bg-center rounded-r"
          style={{
            backgroundImage:
              'url(https://images.unsplash.com/photo-1507608616759-54f48f0af0ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)',
          }}
        />
      </div>
    </main>
  );
};

export default TripLeaderVerify;
