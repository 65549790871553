import { Route, Routes } from 'react-router-dom'
import Confirm from '../Components/Payment/Confirm'
import DetailTrip from '../Components/Payment/DetailTrip'
import PaymentTrip from '../Components/Payment/PaymentTrip'
import Dicairkan from '../Components/PaymentPayout/Dicairkan'
import Diterima from '../Components/PaymentPayout/Diterima'
import AboutUs from '../Pages/AboutUs'
import CaraKerja from '../Pages/CaraKerja'
import Contactus from '../Pages/ContactUs'
import Homepage from '../Pages/Homepage'
import NewTrip from '../Pages/NewTrip'
import PageNotFound from '../Pages/PageNotFound'
import {
  ProfileBank,
  ProfileBio,
  ProfilePayment,
  ProfileSetting,
} from '../Pages/Profile'
import Trip from '../Pages/Trip'
import TripLeaderVerify from '../Pages/TripLeaderVerify'
import Trips from '../Pages/Trips'
import UserProfile from '../Pages/UserProfile'
import BaseLayout from '../Components/Layouts/BaseLayout'
import AdminLayout from '../Components/Layouts/AdminLayout'
import {
  DashboardAdmin,
  LoginAdmin,
  PaymentAdmin,
  VerificationAdmin,
} from '../Pages/admin'
import DetailPaymentAdmin from '../Pages/admin/DetailPaymentAdmin'

export default function BaseRoutes() {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route index element={<Homepage />} />
        <Route path='trips'>
          <Route index element={<Trips />} />
          <Route path=':slug' element={<Trip />} />
        </Route>
        <Route path='carakerja' element={<CaraKerja />} />
        <Route path='newTrips' element={<NewTrip />} />

        <Route path='profile'>
          <Route path=':username'>
            <Route path='trips' element={<UserProfile />} />
            <Route path='account' element={<ProfileBio />} />
            <Route path='bank' element={<ProfileBank />} />
            <Route path='setting' element={<ProfileSetting />} />
            <Route path='payment' element={<ProfilePayment />} />
            <Route path='verify' element={<TripLeaderVerify />} />
          </Route>
        </Route>

        <Route path='payout'>
          <Route path=':slug'>
            <Route index element={<Diterima />} />
            <Route path='dicairkan' element={<Dicairkan />} />
          </Route>
        </Route>

        <Route path='payment'>
          <Route path=':slug'>
            <Route index element={<DetailTrip />} />
            <Route path='pay' element={<PaymentTrip />} />
            <Route path='confirm' element={<Confirm />} />
          </Route>
        </Route>

        <Route path='carakerja' element={<CaraKerja />} />
        <Route path='aboutus' element={<AboutUs />} />
        <Route path='contactus' element={<Contactus />} />

        <Route path='*' element={<PageNotFound />} />
        <Route path='/admin' element={<LoginAdmin />} />
      </Route>

      <Route path='/admin' element={<AdminLayout />}>
        <Route path='dashboard' element={<DashboardAdmin />} />
        <Route path='verifications' element={<VerificationAdmin />} />
        <Route path='payments'>
          <Route index element={<PaymentAdmin />} />
          <Route path=':order_id' element={<DetailPaymentAdmin />} />
        </Route>
      </Route>
    </Routes>
  )
}
