
import { Link, useParams } from 'react-router-dom'

export default function Payment({ children }) {
  const { slug } = useParams()

  return (
    <div className={div}>
      <div className={div2}>
        <h1 className='text-[#6750a4] font-bold text-2xl my-2'>
          Bayar dan Amankan Spot !
        </h1>
        <h3 className='font-semibold'>Travel sekarang!</h3>
        <p className='text-sm my-3'>Pembayaran dijamin aman</p>
      </div>
      <div className='grid grid-cols-2 gap-5 mx-auto text-center mt-20'>
        <div>
          <div className='font-bold my-3 text-sm text-violet-400 hover:text-[#6750a4]'>
            <Link to={`/payment/${slug}`}>Detail Trip</Link>
          </div>
          <p className='text-slate-900 text-xs'>
            Summary Trip yang akan kamu bayar.
          </p>
        </div>

        <div>
          <div className='font-bold my-3 text-sm text-violet-400 hover:text-[#6750a4]'>
            <Link to={`/payment/${slug}/confirm`}>Payment</Link>
          </div>
          <p className='text-slate-900 text-xs'>Yuk bayar trip kamu agar tidak kehabisan tempat!</p>
        </div>
        {children}
      </div>
    </div>
  )
}

const div = 'mt-10 max-w-xl mx-auto text-center items-center grid grid-cols-1'
const div2 = 'my-20 flex flex-col gap-2'
