import Founder from '../assets/images/Founder.png'

export default function AboutUs() {
  return (
    <section className='mt-36 max-w-5xl mx-auto'>
      <div className='grid gap-3 mb-5 md:mb-10 container px-12'>
        <h1 className='font-bold text-2xl text-[#5331ce]'>About Us</h1>
        <p className='text-gray-800'>Temantrip merupakan platform untuk teman-teman yang suka traveling untuk dapat membuat dan mengikuti trip yang dipimpin oleh traveller yang berpengalaman. Tujuan dari temantrip adalah untuk memeudahkan pecinta traveling untuk mendapatkan partner trip. Temantrip, travel bareng teman baru lebih seru</p>
      </div>

      <div className='mx-auto w-full py-5 text-center bg-gray-100 items-center flex flex-col justify-center gap-5'>
        <h2 className='font-bold text-2xl text-[#5331ce]'>The Team</h2>
        <div>
          <img src={Founder} alt="Founder Temantrip" />
        </div>

        <div className='grid text-gray-600 gap-5 w-full font-medium text-sm'>
          <h4 className='font-bold text-main-darker'>Tatia</h4>
          <h3 className='font-extrabold text-[#5331ce] text-xl'>Founder</h3>
          <p className=''>Travel Enthusiast</p>
          <p>UX Designer</p>
          <p>Ex Artpedia (NFT Marketplace)</p>
        </div>
      </div>
    </section>
  )
}
