import { BrowserRouter as Router } from 'react-router-dom'
import ScrollToTop from './utils/ScrollToTop'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getCurrentUser } from './utils/authHelpers'
import { login } from './features/auth/authSlice'
import BaseRoutes from './routes/BaseRoutes'

function App(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    const currentUser = getCurrentUser()
    if (currentUser) {
      const { username, role, user_id } = currentUser
      dispatch(login({ username, role, user_id }))
    }
  }, [props, dispatch])

  return (
    <Router>
      <div className='App min-w-[350px]'>
        <BaseRoutes />

        <ToastContainer
          position='bottom-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </div>
      <ScrollToTop />
    </Router>
  )
}

export default App
