import Input from '../Form/Input';
import Stepper from './Stepper';
import { BsX } from 'react-icons/bs';
import image from '../../assets/trip.png';

function FormTrips({
  register,
  handleSubmit,
  fieldsInc,
  appendInc,
  removeInc,
  fieldsExc,
  appendExc,
  removeExc,
  handleNewTrip,
  fieldsItenaries,
  appendItenaries,
  errors,
  removeItenaries,
}) {
  return (
    <div className="container mx-auto h-fit pt-32 mb-40 px-4 md:px-0">
      <div className="flex gap-[80px] flex-col md:flex-row justify-center">
        <div className="w-[633px] max-w-full h-fit">
          <p className="text-[28px] mb-8">Lengkapi Data Trip Anda</p>
          <Stepper steps={0} />
          <form onSubmit={handleSubmit(handleNewTrip)}>
            <label htmlFor="image" className="block mt-6 text-[14px] text-gray-400">
              Cover Trip
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="file"
              name="image"
              id="image"
              errors={errors?.image?.message}
              {...register('image')}
            />

            <label htmlFor="title" className="block mt-6 text-[14px] text-gray-400">
              Judul Trip
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="text"
              name="title"
              id="title"
              placeholder="Natalan di Solo Yuk"
              errors={errors?.title?.message}
              {...register('title')}
            />

            <label htmlFor="caption" className="block mt-3 text-[14px] text-gray-400">
              Caption
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="text"
              name="caption"
              id="caption"
              placeholder="Caption"
              errors={errors?.caption?.message}
              {...register('caption')}
            />

            <label htmlFor="description" className="block text-[14px] mt-3 text-gray-400">
              Ceritakan Trip Kamu
            </label>
            <textarea
              className="w-full min-h-96 form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              name="description"
              id="description"
              placeholder="Balaikota Solo dipenuhi dengan hiasan natal yang keren sekali"
              {...register('description')}
            />
            {!!errors?.description?.message && (
              <p className="text-sm text-red-500">{errors?.description?.message}</p>
            )}

            <label htmlFor="price" className="block mt-3 text-[14px] text-gray-400">
              Harga
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="number"
              name="price"
              id="price"
              errors={errors?.price?.message}
              {...register('price')}
            />

            <label htmlFor="start_time" className="block mt-3 text-[14px] text-gray-400">
              Start time
            </label>
            <Input
              className="w-full"
              inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
              type="date"
              name="start_time"
              id="start_time"
              errors={errors?.start_time?.message}
              {...register('start_time')}
            />

            <div className="flex gap-4">
              <div className="w-full">
                <label htmlFor="min_mates" className="block mt-6 text-[14px] text-gray-400">
                  Min mate
                </label>
                <Input
                  className="w-full"
                  inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  type="number"
                  name="min_mates"
                  id="min_mates"
                  placeholder="3"
                  errors={errors?.min_mates?.message}
                  {...register('min_mates')}
                />
              </div>
              <div className="w-full">
                <label htmlFor="max_mates" className="block mt-6 text-[14px] text-gray-400">
                  Max mate
                </label>
                <Input
                  className="w-full"
                  inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  type="number"
                  name="max_mates"
                  id="max_mates"
                  {...register('max_mates')}
                />
              </div>
            </div>

            <label className="block mt-6 text-[14px] text-gray-400">Includes</label>
            {fieldsInc.map((field, index) => (
              <div key={field.id}>
                <div className="flex gap-2 mt-2">
                  <Input
                    className="w-full"
                    inputClassName="form-input px-4 py-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                    type="text"
                    name={`includes.${index}.title`}
                    {...register(`includes.${index}.title`)}
                  />
                  <button
                    className="w-[3.2rem] h-12 flex items-center mt-[1px] rounded-full justify-center hover:bg-gray-200 text-gray-700 duration-150 ease-in text-xl"
                    onClick={removeInc}>
                    <BsX />
                  </button>
                </div>
                {index + 1 === fieldsInc.length ? (
                  <div className="flex justify-end">
                    <button
                      className="px-6 py-2 rounded-full bg-[#DDD6F5] text-[#5331CE]"
                      onClick={() => appendInc({ title: '' })}>
                      + Tambah
                    </button>
                  </div>
                ) : null}
              </div>
            ))}

            {fieldsInc.length === 0 ? (
              <div className="flex justify-end">
                <button
                  className="px-6 py-2 rounded-full bg-[#DDD6F5] text-[#5331CE]"
                  onClick={() => appendInc({ title: '' })}>
                  + Tambah
                </button>
              </div>
            ) : null}

            <label className="block mt-4 text-[14px] text-gray-400">Excludes</label>
            {fieldsExc.map((field, index) => (
              <div key={field.id}>
                <div className="flex gap-2 mt-2">
                  <Input
                    className="w-full"
                    inputClassName="form-input px-4 py-3 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                    type="text"
                    name={`excludes.${index}.title`}
                    {...register(`excludes.${index}.title`)}
                  />
                  <button
                    className="w-[3.2rem] h-12 flex items-center mt-[1px] rounded-full justify-center hover:bg-gray-200 text-gray-700 duration-150 ease-in text-xl"
                    onClick={removeExc}>
                    <BsX />
                  </button>
                </div>
                {index + 1 === fieldsExc.length ? (
                  <div className="flex justify-end">
                    <button
                      className="px-6 py-2 rounded-full bg-[#DDD6F5] text-[#5331CE]"
                      onClick={() => appendExc({ title: '' })}>
                      + Tambah
                    </button>
                  </div>
                ) : null}
              </div>
            ))}

            {fieldsExc.length === 0 ? (
              <div className="flex justify-end ">
                <button
                  className="px-6 py-2 rounded-full bg-[#DDD6F5] text-[#5331CE]"
                  onClick={() => appendExc({ title: '' })}>
                  + Tambah
                </button>
              </div>
            ) : null}

            {fieldsItenaries.map((field, index) => (
              <div key={field.id} className="mt-12">
                <label
                  htmlFor={`itenaries.${index}.title`}
                  className="block text-lg mt-6 text-gray-700 font-medium">
                  Kegiatan Hari ke-{index + 1}
                </label>
                <Input
                  className="w-full"
                  inputClassName="form-input px-4 py-3 mt-4 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  type="text"
                  name={`itenaries.${index}.title`}
                  id={`itenaries.${index}.title`}
                  placeholder="Natalan di Solo Yuk"
                  {...register(`itenaries.${index}.title`)}
                />

                <label
                  htmlFor={`itenaries.${index}.place`}
                  className="block text-lg mt-6 text-gray-700 font-medium">
                  Place
                </label>
                <Input
                  className="w-full"
                  inputClassName="form-input px-4 py-3 mt-4 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  type="text"
                  name={`itenaries.${index}.place`}
                  id={`itenaries.${index}.place`}
                  placeholder="Natalan di Solo Yuk"
                  {...register(`itenaries.${index}.place`)}
                />

                <label
                  htmlFor={`itenaries.${index}.images`}
                  className="block text-lg mt-6 text-gray-700 font-medium">
                  Tambahkan Foto Kegiatan
                </label>
                <Input
                  className="w-full"
                  inputClassName="form-input px-4 py-3 mt-4 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  type="file"
                  name={`itenaries.${index}.images`}
                  id={`itenaries.${index}.images`}
                  {...register(`itenaries.${index}.images`)}
                />

                <label
                  htmlFor={`itenaries.${index}.description`}
                  className="block text-lg mt-6 text-gray-700 font-medium">
                  Ceritakan Kegiatan Hari ke-{index + 1}
                </label>
                <textarea
                  className="w-full min-h-96 form-input px-4 py-3 mt-4 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                  name={`itenaries.${index}.description`}
                  id={`itenaries.${index}.description`}
                  placeholder="Balaikota Solo dipenuhi dengan hiasan natal yang keren sekali"
                  {...register(`itenaries.${index}.description`)}
                />

                {index + 1 === fieldsItenaries.length && index < 13 && (
                  <div className="flex justify-end mt-4">
                    <button
                      className="px-6 py-2 rounded-full bg-[#DDD6F5] text-[#5331CE]"
                      onClick={() =>
                        appendItenaries({
                          title: '',
                          description: '',
                          place: '',
                          images: null,
                        })
                      }>
                      + Hari Selanjutnya
                    </button>
                  </div>
                )}
              </div>
            ))}

            <button
              className="mt-8 h-[50px] rounded-full w-full bg-[#5331CE] text-white"
              type="submit">
              Selanjutnya
            </button>
          </form>
        </div>
        <div className="w-[486px] max-w-full h-[800px] relative hidden md:block">
          <img src={image} className="w-full h-full object-cover object-center " alt="cover trip" />
        </div>
      </div>
    </div>
  );
}

export default FormTrips;
