import { useState } from 'react'
import Footer from './Footer'
import Nav from './Nav'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LoginModal, SignupModal, VerificationModal } from '../Modal'
import useChangeBg from '../../hooks/useChangeBg'

export default function BaseLayout() {
  useChangeBg('#fff')
  const { isOpenModal } = useSelector((store) => store.verifications)
  const [isOpen, setIsOpen] = useState(false)
  const [isSignupOpen, setIsSignupOpen] = useState(false)

  return (
    <>
      <header>
        <Nav
          handleLoginModal={() => setIsOpen(!isOpen)}
          handleSignupModal={() => setIsSignupOpen(!isSignupOpen)}
        />
      </header>
      <main>
        <Outlet />
      </main>
      <Footer />

      {!!isOpen && (
        <LoginModal
          isOpen={isOpen}
          handleCloseModal={() => setIsOpen(false)}
          handleSignupModal={() => setIsSignupOpen(true)}
        />
      )}
      {!!isSignupOpen && (
        <SignupModal
          isOpen={isSignupOpen}
          handleCloseModal={() => setIsSignupOpen(false)}
          handleLoginModal={() => setIsOpen(true)}
        />
      )}
      {!!isOpenModal && <VerificationModal />}
    </>
  )
}
