import { Link, useParams } from 'react-router-dom'
import Image from '../../assets/images/media.png'
import Payment from '../../Pages/Payment'
import { useState, useEffect } from 'react'
import priceFmt from '../../utils/priceFormat'
import dateFormat from 'dateformat'

export default function DetailTrip() {
  const { slug } = useParams()
  const [detail, setDetail] = useState([])

  const getDetail = async () => {
    const url = await fetch(`${process.env.REACT_APP_API_URL}/products/${slug}`)
    const data = await url.json()
    setDetail(data.data)
  };

  useEffect(() => {
    getDetail()
  }, [])

  // format date
  // const date = dateFormat(detail.detail.start_time, 'mmm dd')
  // if(date === undefined){
  //   return console.log('error')
  // } else {
  //   return date
  // }

  return (
    <Payment className='max-w-xl w-screen col-span-2'>
      <div className={div}>
        <div className='grid grid-cols-2 bg-violet-300 rounded p-2 gap-5'>
          <div className='text-start text-sm'>
            <h1 className='text-lg font-semibold text-[#6750a4]'>
              {detail.title}
            </h1>
            <h4 className='font-semibold text-[#6750a4]'></h4>
            <p className='font-semibold text-[#7850a4]'></p>
            <h2 className='font-semibold text-[#6750a4]'></h2>
          </div>

          <div className='max-h-40 overflow-hidden'>
            <img className='rounded-md' src={process.env.REACT_APP_URL + detail.image} alt={detail.title} />
          </div>

          <div className='flex col-span-2 justify-between text-sm bg-white p-1 rounded-md mx-3'>
            <h3>Total Harga</h3>
            <h3>
              {detail.price}
            </h3>
          </div>
        </div>

        <div className={div2}>
          <button className={btn} >
            <Link to={`/payment/${slug}/confirm`}>
              Selanjutnya
            </Link>
          </button>
        </div>
      </div>
    </Payment>
  )
}
const div = 'my-16 mx-auto flex flex-col col-span-2'

const div2 ='w-24 mt-20 py-1 mx-auto text-sm rounded-full bg-[#6750a4] text-[#fff]'
const btn = 'px-2 outline-none'
