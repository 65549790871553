import iconTrip from '../../assets/icons/admin-trip.png'
import iconPayout from '../../assets/icons/admin-payout.png'
import iconContact from '../../assets/icons/admin-contact.png'
import iconPayment from '../../assets/icons/admin-payment.png'
import iconTripleader from '../../assets/icons/admin-trip-leader.png'
export default function DashboardAdmin() {
  return (
    <>
      <p className='text-xl text-[#303030] mb-8'>Ringkasan</p>
      <div className='flex gap-6'>
        <div>
          <p className='text-gray-600 m-3'>Pengajuan</p>
          <div className='flex gap-10 p-8 bg-[#F1F5F9] rounded'>
            <div className='flex gap-2'>
              <img src={iconTripleader} alt='icon' />
              <div>
                <p className='text-2xl text-[#5331CE]'>45</p>
                <p className='text-sm text-[#666666]'>Trip Leader</p>
              </div>
            </div>
            <div className='flex gap-2'>
              <img src={iconPayout} alt='icon' />
              <div>
                <p className='text-2xl text-[#5331CE]'>178</p>
                <p className='text-sm text-[#666666]'>Payout</p>
              </div>
            </div>
            <div className='flex gap-2'>
              <img src={iconTrip} alt='icon' />
              <div>
                <p className='text-2xl text-[#5331CE]'>67</p>
                <p className='text-sm text-[#666666]'>Buat trip</p>
              </div>
            </div>
            <div className='flex gap-2'>
              <img src={iconContact} alt='icon' />
              <div>
                <p className='text-2xl text-[#5331CE]'>488</p>
                <p className='text-sm text-[#666666]'>Contact Us</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className='text-gray-600 m-3'>Payment</p>
          <div className='flex gap-10 p-8 bg-[#F1F5F9] rounded'>
            <div className='flex gap-2'>
              <img src={iconPayment} alt='icon' />
              <div>
                <p className='text-2xl text-[#5331CE]'>45</p>
                <p className='text-sm text-[#666666]'>Payment ikut trip</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
