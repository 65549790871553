import heroStock_2 from '../../assets/images/hero-stock-2.jpg';
import { motion } from 'framer-motion';
import Button from '../Form/Button';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navTo = useNavigate();
  const trip = () => {
    navTo('trips');
  };

  return (
    <div
      style={{ backgroundImage: `url(${heroStock_2})` }}
      className="px-5 xl:px-0 h-[37rem] lg:h-[750px] bg-cover bg-center-top 2xl:bg-[center_top_-10rem] bg-main-color">
      <div className="inner-hero h-full container mx-auto flex items-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={{ ease: 'easeIn', duration: 0.3 }}
          className="hero-wrapper basis-full text-center lg:text-left lg:basis-1/2">
          <h1 className="font-bold text-4xl leading-[50px] sm:text-5xl sm:leading-[75px]">
            TRIP BARENG TEMEN BARU LEBIH SERU!
          </h1>
          <p className="text-lg mt-3 w-full lg:w-3/4 mb-7">
            Travel bersama dan buat pengalaman yang mengesankan.
          </p>
          <Button onClick={trip} className="cta">
            CARI TRIP
          </Button>
        </motion.div>
      </div>
    </div>
  );
};
export default Hero;
