import { useQuery } from 'react-query'
import axios from 'axios'
import TripCard from '../Components/Cards/TripCard'
import ErrorComponent from '../Components/Fallbacks/ErrorComponent'
import LoadingSkeleton from '../Components/Fallbacks/LoadingSkeleton'

async function getProducts() {
  const data = await axios(`${process.env.REACT_APP_API_URL}/products`)
  return data
}

const Trips = () => {
  const { status, data } = useQuery('products', getProducts)

  return (
    <>
      <main className='pt-28 container'>
        <h1 className='text-2xl font-medium mb-5 text-center'>Trip Pilihan</h1>
        <p className='text-center mb-8 text-gray-800 text-sm px-5 sm:px-10'>
          Perjalanan yang di pimpin oleh trip leader yang berpengalaman yang
          membuat pengalaman perjalanan anda jauh lebih menyenangkan
        </p>
        <div className='trips-card-wrapper grid grid-cols-fluid gap-3 justify-items-center'>
          {status === 'loading' ? <LoadingSkeleton /> : null}
          {status === 'error' ? <ErrorComponent /> : null}
          {status === 'success'
            ? data.data.data.map((trip, index) => <TripCard trip={trip} key={index} />)
            : null}
        </div>
      </main>
    </>
  )
}
export default Trips
