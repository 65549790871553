import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import adminReducer from '../features/auth/adminSlice'
import profileReducer from '../features/profileSlice'
import verificationReducer from '../features/admin/verificationSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    admin: adminReducer,
    verifications: verificationReducer
  }
})
