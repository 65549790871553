import { useState, useEffect, useCallback } from 'react';
import { AiOutlineGoogle, AiOutlineMail, AiOutlineClose } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { motion } from 'framer-motion';
import Button from '../Form/Button';
import Input from '../Form/Input';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../features/auth/authSlice';
import jwt from 'jwt-decode';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  email: yup.string().email('Email is invalid').required('Email is required'),
  password: yup
    .string()
    .required('Password is requried')
    .min(8, 'Password must be atleast 8 characters'),
});

const LoginModal = ({ isOpen = false, handleCloseModal, handleSignupModal }) => {
  const [loginAuth, setLoginAuth] = useState('');
  const [isUseEmail, setIsUseEmail] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEscKey = useCallback(
    e => {
      if (e.key === 'Escape') {
        handleCloseModal();
        setIsUseEmail(false);
      }
    },
    [handleCloseModal],
  );

  useEffect(() => {
    setLoginAuth(localStorage.getItem('user'));
    document.addEventListener('keyup', handleEscKey);
    return () => {
      document.removeEventListener('keyup', handleEscKey);
    };
  }, [handleEscKey]);

  const handleSwitchModal = () => {
    handleCloseModal();
    handleSignupModal();
    setIsUseEmail(false);
  };

  const handleLogin = data => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, data)
      .then(function (response) {
        if (response.status === 200) {
          //update current user auth state
          const data = jwt(response.data.data);
          const { username, role } = data;
          dispatch(login({ username, isTripLeader: role }));

          //clear/clean login modal
          handleCloseModal();
          setValue('email', '');
          setValue('password', '');
          setIsLoginLoading(false);
          localStorage.setItem('user', JSON.stringify(response.data.data));
          navigate('/trips');
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoginLoading(false);

        toast.error('Terjadi kesalahan: ' + error?.response.data.message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const LoginOptions = () => {
    return (
      <>
        <div className="text-sm font-light text-slate-500 mb-1.5 pl-0.5">Lanjutkan dengan...</div>
        <div className="flex gap-2 mb-6">
          <a href={`${process.env.REACT_APP_API_URL}/auth/google`}>
            <button
              className="p-3 w-28 h-24 border rounded text-sm hover:border-main-color transition-all">
              <AiOutlineGoogle size={24} className="mx-auto mb-4" />
              <span>Google</span>
            </button>
          </a>
          <button className="p-3 w-28 h-24 border rounded text-sm hover:border-main-color transition-all">
            <RiFacebookFill size={24} className="mx-auto mb-4" />
            <span>Facebook</span>
          </button>
          <button
            onClick={() => setIsUseEmail(true)}
            className="p-3 w-28 h-24 border rounded text-sm hover:border-main-color transition-all">
            <AiOutlineMail size={24} className="mx-auto mb-4" />
            <span>Email</span>
          </button>
        </div>
      </>
    );
  };

  const LoginWithEmail = () => {
    return (
      <form
        onSubmit={handleSubmit(data => {
          setIsLoginLoading(true);
          handleLogin(data);
        })}
        className="register-form -mt-14">
        <label htmlFor="email" className="text-gray-400">
          Email
        </label>
        <Input
          className="w-full"
          type="email"
          name="email"
          id="signup_email"
          placeholder="Email"
          errors={errors?.email?.message}
          {...register('email')}
        />
        <label htmlFor="signup_password" className="text-gray-400">
          Password
        </label>
        <Input
          className="w-full mb-5"
          type="password"
          name="password"
          id="signup_password"
          placeholder="Password"
          errors={errors?.password?.message}
          {...register('password')}
        />
        <Button className="w-full mb-3 min-h-[49.5px]" type="submit" isLoading={isLoginLoading}>
          Masuk
        </Button>
      </form>
    );
  };

  return (
    <div
      className={`modal-container fixed z-50 top-0 w-full h-full ${!isOpen && 'hidden'}`}
      style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
      <div className="modal-wrapper h-screen flex">
        {isOpen && (
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.15 }}
            className="inner-modal bg-white w-[48rem] m-auto rounded flex">
            <div className="grow flex rounded-l">
              <img
                src="https://images.unsplash.com/photo-1511300636408-a63a89df3482?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d2FsbHBhcGVyfGVufDB8fDB8fA%3D%3D&w=500&q=80"
                alt="floating"
                className="object-center object-cover rounded-l"
              />
            </div>
            <div className="basis-1/2 shrink-0 p-10 pb-6 min-w-[378px]">
              <div className="flex justify-between mb-1.5">
                <h2 className="text-2xl font-bold text-main-darker">Masuk</h2>
                <button
                  className="close-btn -mt-10 -mr-6 px-3 hover:opacity-40 opacity-60 hover:transition-all"
                  onClick={() => {
                    handleCloseModal();
                    setIsUseEmail(false);
                  }}>
                  <AiOutlineClose size={18} strokeWidth={36} />
                </button>
              </div>
              <p className="text-sm font-light mb-32 text-slate-500">
                Mulai petualanganmu sekarang juga!
              </p>

              {isUseEmail ? <LoginWithEmail /> : <LoginOptions />}
              <div className="mb-16">
                <p className="text-sm pl-0.5 text-slate-500">
                  Belum punya akun?&nbsp;
                  <button
                    onClick={() => handleSwitchModal()}
                    className="text-main-color hover:underline">
                    Daftar
                  </button>
                </p>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default LoginModal;
