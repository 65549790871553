import axios from 'axios';
import React from 'react';
import Input from '../../Components/Form/Input';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../../features/auth/adminSlice';
import jwt from 'jwt-decode';

export default function LoginAdmin() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = data => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin`, data)
      .then(function (response) {
        if (response.status === 200) {
          const data = jwt(response.data.data);
          const { username, user_id } = data;
          dispatch(login({ username, user_id }));

          localStorage.setItem('ADMIN', JSON.stringify(response.data.data));
          navigate('/admin/dashboard');
        }
      })
      .catch(function (error) {
        toast.error('Terjadi kesalahan: ' + error?.message, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <div className="min-h-full w-full flex justify-center items-center mt-32 mb-20">
      <div className="w-[800px] max-w-full">
        <form onSubmit={handleSubmit(handleLogin)}>
          <label htmlFor="email" className="block mt-6 text-[14px] text-gray-400">
            Email
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            required
            {...register('email')}
          />
          <label htmlFor="password" className="block mt-6 text-[14px] text-gray-400">
            Password
          </label>
          <Input
            className="w-full"
            inputClassName="form-input px-4 py-3 mt-2 rounded focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            required
            {...register('password')}
          />
          <button className="h-[50px] w-full bg-[#5331CE] text-white mt-4 rounded">Login</button>
        </form>
      </div>
    </div>
  );
}
