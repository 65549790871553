import React from 'react'
import ReactNiceAvatar, { genConfig } from 'react-nice-avatar'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/ttrip.png'

export default function NavAdmin() {
  const avatarConfig = genConfig('admin')

  return (
    <div className='bg-white fixed top-0 left-0 py-3 w-full z-50 shadow-md shadow-gray-200/80'>
      <div className='container mx-auto flex justify-between items-center'>
        <div>
          <Link to='/'>
            <img className='h-12' src={logo} alt='Teman trip logo' />
          </Link>
        </div>
        <div className='avatar-wrapper relative'>
          <ReactNiceAvatar
            className='w-9 h-9 border-2 border-main-color'
            {...avatarConfig}
          />
        </div>
      </div>
    </div>
  )
}
