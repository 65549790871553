import jwt from "jwt-decode";

export const getCurrentUser = () => {
  if (localStorage.getItem("user")) {
    const jwtDecoded = jwt(JSON.parse(localStorage.getItem("user")));

    return jwtDecoded;
  }
};

export const getHeadersConfig = () => {
  if (localStorage.getItem("user")) {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    return config;
  }
};
