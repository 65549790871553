import Input from './Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../assets/images/credit card.png';
import Payment from '../../Pages/Payment';

export default function PaymentTrip() {
  const { slug } = useParams();
  const navTo = useNavigate();

  const valid = yup.object().shape({
    email: yup
      .string()
      .email('Please input a correct email addres!')
      .required('Email must be valid!'),
    firstname: yup.string().required('First Name must be valid!'),
    lastname: yup.string().required('Last Name must be valid!'),
    cardnumber: yup.string().required('Card Number must be valid!'),
    mmyy: yup.string().required('MM/YY must be valid!'),
    cvv: yup.string().required('CVV must be valid!'),
    namecard: yup.string().required('Name Card must be valid!'),
    country: yup.string().required('Country must be valid!'),
    postcode: yup.string().required('Postal Code must be valid!'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(valid),
  });

  const callBackSubmit = data => {
    navTo(`/payment/${slug}/confirm`);
  };

  return (
    <Payment>
      <section className={section}>
        <div className={div}>
          <div className={divImg}>
            <img src={Card} alt="card iamge" />
          </div>

          <div className={div1}>
            <p className="text-start text-sm text-semibold mb-2">Debit or Credit Card</p>
            <form className={form} onSubmit={handleSubmit(callBackSubmit)}>
              <div className="w-full">
                <Input type="email" id="email" place="Email Addres" {...register('email')} />
                <span className={span}>{errors.email?.message}</span>
              </div>

              <div className={div1child1}>
                <div>
                  <Input type="text" id="firstname" place="First Name" {...register('firstname')} />
                  <span className={span}>{errors.firstname?.message}</span>
                </div>

                <div>
                  <Input type="text" id="lastname" place="Last Name" {...register('lastname')} />
                  <span className={span}>{errors.lastname?.message}</span>
                </div>
              </div>

              <div className={div1child2}>
                <div className="col-span-2">
                  <Input
                    type="number"
                    id="cardnumber"
                    place="Card Number"
                    {...register('cardnumber')}
                  />
                  <span className={span}>{errors.cardnumber?.message}</span>
                </div>
                <div>
                  <Input type="number" id="mmyy" place="MM/YY" {...register('mmyy')} />
                  <span className={span}>{errors.mmyy?.message}</span>
                </div>
                <div>
                  <Input type="number" id="cvv" place="CVV" {...register('cvv')} />
                  <span className={span}>{errors.cvv?.message}</span>
                </div>
              </div>

              <div className="w-full">
                <Input type="text" id="namecard" place="Name on Card" {...register('namecard')} />
                <span className={span}>{errors.namecard?.message}</span>
              </div>

              <div className={div1child1}>
                <div>
                  <Input type="text" id="country" place="Country" {...register('country')} />
                  <span className={span}>{errors.country?.message}</span>
                </div>
                <div>
                  <Input
                    type="number"
                    id="postcode"
                    place="Postal Code"
                    {...register('postcode')}
                  />
                  <span className={span}>{errors.postcode?.message}</span>
                </div>
              </div>

              <div className={divBtn}>
                <button className={btn} onClick={() => navTo(`/payment/${slug}/confirm`)}>
                  Selanjutnya
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Payment>
  );
}

const section = 'max-w-xl w-screen';
const div = 'grid grid-cols-2 mt-16 my-10';

const divImg = 'grid place-items-end col-span-2';

const div1 = 'flex flex-col col-span-2 my-10';
const form = 'grid place-items-start w-full text-start';
const span = 'text-red-500 text-xs';
const div1child1 = 'grid gap-2 grid-cols-2 w-full';
const div1child2 = 'grid grid-cols-4 gap-2';

const divBtn = 'w-24 mt-20 py-1 mx-auto text-sm rounded-full bg-[#6750a4] text-[#fff]';
const btn = 'px-2 outline-none';
