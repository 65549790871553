import React from 'react'
import NavAdmin from './admin/NavAdmin'
import SidebarAdmin from './admin/SidebarAdmin'
import { Outlet } from 'react-router-dom'
import useChangeBg from '../../hooks/useChangeBg'

export default function AdminLayout() {
  useChangeBg('#F9F9F9')
  return (
    <>
      <NavAdmin />
      <main>
        <SidebarAdmin />
        <div className='w-[calc(100%-270px)] ml-[270px] pl-6 pr-20 py-28'>
          <Outlet />
        </div>
      </main>
    </>
  )
}
