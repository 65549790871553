function getJwtFromLocal(name = 'user') {
  let isLocal = false

  if (localStorage.getItem(name)) {
    const jwt = JSON.parse(localStorage.getItem(name))
    isLocal = true
    return { isLocal, jwt }
  }

  return { isLocal }
}

export { getJwtFromLocal }