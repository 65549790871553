import priceFormat from '../../utils/priceFormat'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const TripCard = ({ trip }) => {
  return (
    <motion.div
      transition={{ ease: 'easeIn', duration: 0.15 }}
      className=' bg-white rounded-xl w-full border mb-8'
    >
      <div className='card-wrapper'>
        <div className='card-header flex gap-3.5 items-center justify-between px-3 py-2'>
          <div className='flex gap-3 items-center'>
            <img
              src={
                trip.author.avatar === undefined
                  ? `https://placeholder.pics/svg/100`
                  : trip.author.avatar
              }
              alt={trip.author.name}
              className='rounded-full w-10 h-10 object-cover object-center'
            />
            <div>
              <div className='trip-leader font-semibold'>
                {trip.author.name}
              </div>
            </div>
          </div>
          <div>
            <p className='text-xs text-end'>Harga Perjalanan</p>
            <div className='price text-lg text-[#5331ce] font-semibold'>
              {priceFormat(trip.price)}
            </div>
          </div>
        </div>
        <div className='trip-img w-full h-64'>
          <img
            src={process.env.REACT_APP_URL + trip.image}
            alt={trip.title}
            className='w-full h-full object-cover sm:object-center'
          />
        </div>
        <div className='trip-details py-5 px-6 flex flex-col'>
          <div className='title font-bold mb-5'>{trip.title}</div>
          <div className='sub-caption mb-6 text-left text-sm max-h-20 overflow-hidden'>
            {trip.description}
          </div>
          <Link
            to={`/trips/${trip.slug}`}
            className='border-main-color border-2 text-sm text-main-color font-semibold py-1.5 px-4 hover:bg-main-color hover:text-white rounded-full hover:transition-all active:bg-main-dark mr-auto mb-2 w-full text-center'
          >
            Lihat Selengkapnya
          </Link>
        </div>
      </div>
    </motion.div>
  )
}
export default TripCard
