import clsx from 'clsx';
import React from 'react';

const Input = React.forwardRef(
  (
    {
      type,
      className,
      style,
      onClick,
      onChange,
      register,
      errors,
      name,
      id,
      placeholder,
      required,
      minLength,
      max,
      min,
      inputClassName,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <div className={`input-wrapper flex flex-col ${className}`}>
        <input
          ref={ref}
          type={type}
          className={clsx(`text-based-form`, inputClassName, {
            'border-red-500': !!errors,
          })}
          style={style}
          onClick={onClick}
          onChange={onChange}
          id={id}
          placeholder={placeholder}
          name={name}
          minLength={minLength}
          max={max}
          min={min}
          {...inputProps}
          formNoValidate
        />
        {!!errors && <p className="text-sm text-red-500 -mt-2 mb-4">{errors}</p>}
      </div>
    );
  },
);

Input.displayName = 'Input';
export default Input;
