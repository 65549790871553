import { IoLocationOutline } from "react-icons/io5";

const TripBlock = ({ trip }) => {
  return (
    <div className="flex gap-5 mb-5 border rounded h-36 pr-3">
      <div className="trip-image w-36 h-full shrink-0">
        <img
          src={trip.img}
          alt={trip.title}
          className="w-full h-full object-cover object-center rounded-l"
        />
      </div>
      <div className="trip-details py-5 relative w-full">
        <strong>{trip.title}</strong>
        <p className="text-sm text-gray-500 mb-2">12 Mei 2022 - 15 Mei 2022</p>
        <p className="text-sm">{trip.caption}</p>
        <div className="flex items-center text-gray-500 gap-1 absolute right-3 bottom-3 font-bold text-sm">
          <IoLocationOutline size={18} />
          <div>{trip.location}</div>
        </div>
      </div>
    </div>
  );
};
export default TripBlock;
