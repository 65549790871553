import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ProfileVerifyTrips from '../Components/Trips/ProfileVerifyTrips';
import FormTrips from '../Components/Trips/FormTrips';
import FormCostBreakdown from '../Components/Trips/FormCostBreakdown';
import PreviewTrip from '../Components/Trips/PreviewTrip';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  caption: yup.string().required('Caption is required'),
  description: yup.string().required('Descriptoin is required'),
  price: yup.string().required('Price is requried'),
  start_time: yup.string().required('Start Time is required'),
  min_mates: yup.string().required('Min mate is required'),
  image: yup.mixed().test('fileSize', 'Image is required', value => {
    if (!value.length) return false;
    return true;
  }),
});

export default function NewTrip() {
  const user = useSelector(s => s.auth);
  const [steps, setSteps] = useState(0);
  const [tmpData, setTmpData] = useState({});

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      includes: [
        {
          title: '',
        },
      ],
      excludes: [
        {
          title: '',
        },
      ],
      itenaries: [
        {
          title: '',
          description: '',
          place: '',
          images: null,
        },
      ],
    },
  });

  const {
    fields: fieldsIncludes,
    append: appendIncludes,
    remove: removeIncludes,
  } = useFieldArray({
    control,
    name: 'includes',
  });

  const {
    fields: fieldsExcludes,
    append: appendExcludes,
    remove: removeExcludes,
  } = useFieldArray({
    control,
    name: 'excludes',
  });

  const {
    fields: fieldsItenaries,
    append: appendItenaries,
    remove: removeItenaries,
  } = useFieldArray({
    control,
    name: 'itenaries',
  });

  const handleNewTrip = data => {
    setTmpData({ ...data });
    setSteps(1);
  };

  switch (steps) {
    case 1:
      return <FormCostBreakdown handleStep={setSteps} />;
    case 2:
      return <PreviewTrip data={tmpData} user={user} handleStep={setSteps} />;
    case 3:
      return <ProfileVerifyTrips handleStep={setSteps} user={user} />;
    case 0:
    default:
      return (
        <FormTrips
          register={register}
          handleSubmit={handleSubmit}
          fieldsInc={fieldsIncludes}
          appendInc={appendIncludes}
          removeInc={removeIncludes}
          fieldsExc={fieldsExcludes}
          appendExc={appendExcludes}
          removeExc={removeExcludes}
          handleNewTrip={handleNewTrip}
          errors={errors}
          fieldsItenaries={fieldsItenaries}
          appendItenaries={appendItenaries}
          removeItenaries={removeItenaries}
        />
      );
  }
}
