const LoadingSkeleton = () => {
  return (
    <div className="rounded p-4 mb-16 h-full w-full mx-auto">
      <div className="animate-pulse flex flex-col gap-4">
        <div className="bg-gray-300 rounded w-full h-36" />
        <div className="bg-gray-300 rounded w-full h-24" />
        <div className="bg-gray-300 rounded w-full h-96" />
        <div className="bg-gray-300 rounded w-full h-12" />
        <div className="bg-gray-300 rounded w-full h-64" />
      </div>
    </div>
  );
};
export default LoadingSkeleton;
