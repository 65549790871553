import Avatar, { genConfig } from 'react-nice-avatar';
import { BsStarFill } from 'react-icons/bs';
import iconTiket from '../../assets/icons/icon-tiket.png';
import iconTransportasi from '../../assets/icons/icon-transportasi.png';
import iconAkomodasi from '../../assets/icons/icon-akomodasi.png';
import iconKonsumsi from '../../assets/icons/icon-konsumsi.png';
import { getJwtFromLocal } from '../../utils/getJwt';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function PreviewTrip({ data, user, handleStep }) {
  const avatarConfig = genConfig(user.username);

  const sendData = () => {
    const { isLocal, jwt } = getJwtFromLocal();

    if (!isLocal) {
      return;
    }

    const body = {
      title: data.title,
      caption: data.caption,
      description: data.description,
      price: data.price,
      min_mates: data.min_mates,
      image: data.image[0],
      start_time: data.start_time,
    };

    if (data.max_mates > 0) {
      body.max_mates = data.max_mates;
    }

    let includesTmp = [];
    data.includes.map(inc => includesTmp.push(inc.title));

    let includes = includesTmp.join(', ');

    let excludesTmp = [];
    data.excludes.map(inc => excludesTmp.push(inc.title));

    let excludes = excludesTmp.join(', ');

    body.includes = includes;
    body.excludes = excludes;

    axios
      .post(`${process.env.REACT_APP_API_URL}/products`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(res => {
        if (res.status === 201) {
          toast.success('Success create product', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          sendItenaries(body.title, jwt);
        }
        if (res.status === 403) {
          handleStep(4);
        }
      })
      .catch(error => {
        console.log(error);
        toast.error(error, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const handleConvert = async image => {
    let base64 = await convertToBase64(image);
    return base64.slice(23);
  };

  const sendItenaries = (slug, jwt) => {
    let slugProduct = slug.toLowerCase().split(' ').join('-');

    const itenaries = data.itenaries?.map(itenary => ({
      ...itenary,
      images: handleConvert(itenary.images[0]),
    }));

    let newItenaries = itenaries?.map(itenary => {
      return {
        title: itenary.title,
        place: itenary.place,
        description: itenary.description,
        images: [
          {
            image_string: itenary.images,
          },
        ],
      };
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/products/${slugProduct}`, newItenaries, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(res => {
        if (res.status === 201) {
          toast.success('Success create product', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error(error, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const convertToBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  return (
    <div className="container mx-auto h-fit pt-32 mb-40 px-4 md:px-0">
      <div className="w-full h-[500px] rounded-[23px] overflow-hidden">
        <img
          src={URL.createObjectURL(data.image[0])}
          alt="cover trip"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="mt-[94px] flex flex-col md:flex-row gap-[77px] items-start">
        <div className="w-[818px] max-w-full">
          <p className="text-[32px] mb-[12px]">{data.title}</p>
          <div className="flex gap-2 items-center">
            <Avatar className="w-[36px] h-[36px] border-2 border-main-color" {...avatarConfig} />
            <p>{user.username}</p>
          </div>
          <p className="text-[#666] mt-[12px] text-[12px]">5.00 of 27 reviews</p>
          <div className="flex gap-2 text-[#5331CE]">
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
            <BsStarFill />
          </div>
          <p className="mt-[32px]">{data.description}</p>

          <p className="mt-[32px] mb-[12px] font-semibold">Harga Termasuk</p>
          <div className="flex gap-4">
            {includes.map((inc, index) => (
              <div className="flex gap-2 items-center" key={index}>
                <div className="h-[44px] w-[44px] rounded bg-[#372189] flex items-center justify-center">
                  <img src={inc.image} alt={`icon ${inc.value}`} />
                </div>
                <p>{inc.value}</p>
              </div>
            ))}
          </div>
          <p className="text-[24px] mt-[36px] font-semibold">Rencana Perjalanan</p>
          <p className="mb-6">Pelajari Rangkaian Perjalanan Trip Anda</p>
          {/* {data.itenaries?.map((itenary, index) => (
            <div key={index} className="py-[24px]">
              <span className="px-[24px] py-[12px] rounded bg-[#DDD6F5] text-[#2A1967] font-medium">
                Kegiatan Hari Ke-{index + 1}
              </span>
              <div className="flex gap-2 mt-[18px] flex-col md:flex-row">
                <div className="w-[400px] max-w-full">
                  <p className="text-[18px] font-bold">{itenary.title}</p>
                  <p className="mt-[12px]">{itenary.description}</p>
                </div>
                <div className="w-[400px] max-w-full h-[400px] rounded-lg overflow-hidden">
                  <img
                    src={URL.createObjectURL(itenary?.images[0])}
                    alt={itenary?.title}
                    className="h-full object-cover object-center"
                  />
                </div>
              </div>
            </div>
          ))} */}
          <div className="flex justify-between mt-[12px] font-medium gap-[24px]">
            <button
              onClick={() => handleStep(prev => prev - 1)}
              className="flex-1 rounded-full h-[50px] border border-[#5331CE] text-[#5331CE]">
              Kembali
            </button>
            <button
              onClick={sendData}
              className="flex-2 rounded-full border border-[#5331CE] bg-[#5331CE] text-white w-[692px] h-[50px]">
              Publish Sekarang
            </button>
          </div>
        </div>
        <div className="p-6 rounded-[13px] shadow-gray-200 shadow-lg w-[256px] max-w-full">
          <p className="text-[#4529AC] text-[24px] font-medium text-center">Rp {data.price}</p>
          <div className="flex justify-between">
            <p>Lama Perjalanan</p>
            <p>4 hari</p>
          </div>
          <div className="flex gap-[20px] justify-between">
            <div className="flex-1 text-center px-2 py-4 rounded border border-gray-300">
              <p className="text-[#5331CE] font-semibold">Berangkat</p>
              <p>{data.start_time}</p>
            </div>
            <div className="flex-1 text-center px-2 py-4 rounded border border-gray-300">
              <p className="text-[#5331CE] font-semibold">Pulang</p>
              <p>{data.start_time}</p>
            </div>
          </div>
          <button className="h-[48px] w-full mt-8 rounded-full font-medium text-white bg-[#5331CE]">
            Publish Trip
          </button>
        </div>
      </div>
    </div>
  );
}

const includes = [
  {
    value: 'Tiket Wisata',
    image: iconTiket,
  },
  {
    value: 'Transportasi',
    image: iconTransportasi,
  },
  {
    value: 'Akomodasi',
    image: iconAkomodasi,
  },
  {
    value: 'Konsumsi',
    image: iconKonsumsi,
  },
];
