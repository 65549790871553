import axios from 'axios'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getJwtFromLocal } from '../../utils/getJwt'
import { BsChevronLeft } from 'react-icons/bs'

async function fetchDetailPayment(id) {
  const { isLocal, jwt } = getJwtFromLocal('ADMIN')

  if (!isLocal) {
    return
  }

  const headers = {
    Authorization: `Bearer ${jwt}`,
  }

  const res = await axios(
    `${process.env.REACT_APP_API_URL}/admin/payments/${id}`,
    {
      headers,
    }
  )
  return res
}

export default function DetailPaymentAdmin() {
  const { order_id } = useParams()
  const { data, status } = useQuery('payments', fetchDetailPayment(order_id))
  const navigate = useNavigate()

  useEffect(() => {
    if (status === 'error') {
      toast.error('Gagal terkoneksi berikut data sampel', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }, [status])

  if (status === 'loading') {
    return (
      <>
        <div className='flex justify-center items-center gap-3'>
          <p>Loading ...</p>
        </div>
      </>
    )
  }
  if (status === 'error') {
    return (
      <>
        <div className='text-center relative w-full'>
          <div
            className='absolute left-4 top-1/2 -translate-y-1/2'
            onClick={() => navigate('/admin/payments')}
          >
            <BsChevronLeft />
          </div>
          <p className='text-xl font-semibold text-main-color'>
            Ke Bali Bersama Remote Worker
          </p>
        </div>
        <div className='mt-6'>
          <p className='text-sm'>Lebih di cantik di Bali</p>
          <p className='mt-2'>
            Ingin ke Bali bersama remote worker atau sesama pekerja tech? Hayuk
            Januari ke Bali bersama Tatia, admin kitakerjaremote.com .
            Seru-seruan bersama orang yang sefrekuensi pasti nyambung! Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam
            vel eius, pariatur possimus consectetur sint soluta ad labore
            accusantium aspernatur reprehenderit delectus quaerat sit odio ullam
            repellendus! Nihil, distinctio pariatur libero voluptates, hic animi
            modi adipisci at asperiores corrupti assumenda, aspernatur enim
            optio. Earum vitae porro sit voluptatibus illum excepturi amet?
            Cupiditate, reprehenderit! Illo reiciendis ipsa placeat aut. Vero
            possimus debitis, aut sit quos non, quas impedit ducimus reiciendis
            et soluta atque natus neque corrupti adipisci incidunt rem maxime
            culpa quae suscipit? Exercitationem error illo mollitia, quasi quae,
            ipsum, asperiores unde laborum tempore at veritatis modi. Fuga,
            quod. Alias!
          </p>
        </div>
      </>
    )
  }

  if (status === 'success') {
    return (
      <>
        <div className='text-center relative w-full'>
          <div
            className='absolute left-4 top-1/2 -translate-y-1/2'
            onClick={() => navigate('admin/payments')}
          >
            <BsChevronLeft />
          </div>
          <p
            className='text-xl font-semibold text-main-color'
            onClick={() => navigate(`trip/${data.data.data.slug}`)}
          >
            {data.data.data.title}
          </p>
        </div>
        <div className='mt-6'>
          <p className='text-sm'>{data.data.data.caption}</p>
          <p className='mt-2'>{data.data.data.description}</p>
        </div>
      </>
    )
  }
}
