import { useEffect, useRef } from 'react'

const useChangeBg = (color) => {
  const colorBg = useRef(color)
  useEffect(() => {
    document.body.style.background = colorBg?.current
  }, [colorBg])
}

export default useChangeBg
