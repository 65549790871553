import Button from '../Components/Form/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import LoadingSkeleton from '../Components/Fallbacks/LoadingSkeleton';
import ErrorComponent from '../Components/Fallbacks/ErrorComponent';

async function getProduct({ queryKey }) {
  const [_, slug] = queryKey;
  const data = await axios(`${process.env.REACT_APP_API_URL}/products/${slug}`);
  return data;
}

const Trip = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { status, data } = useQuery(['detailproduct', slug], getProduct);

  // post to /checkout when user click Pesan
  const postCheckout = async () => {
    const token = JSON.parse(localStorage.getItem('user'));
    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');
    let raw = JSON.stringify({ quantity: 1, slug });
    const req = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };
    const api = await fetch(`${process.env.REACT_APP_API_URL}/checkout`, req);
    const respone = await api.json();
  };

  const handleOrder = () => {
    postCheckout();
    navigate(`/payment/${slug}`);
  };

  if (status === 'loading') {
    return <LoadingSkeleton />;
  }
  if (status === 'error') {
    return <ErrorComponent />;
  }

  if (status === 'success') {
    const { title, image, price, detail, author, description, itenaries } = data.data.data;
    return (
      <>
        <main className="pt-20 container">
          <div className="w-full my-4">
            <img
              src={process.env.REACT_APP_URL + image}
              alt={title}
              className="rounded h-64 sm:h-96 lg:h-[36rem] w-full object-cover object-center"
            />
          </div>
          <div className="main-content-wrapper flex gap-16">
            <div className="mt-10">
              <section className="details-wrapper">
                <div className="details">
                  <div className="mb-4">
                    <h1 className="text-2xl font-bold mt-6">{title}</h1>
                    <div className="text-main-color text-sm font-semibold">
                      <span>{author.name}</span> | <span>Rp {price}</span> |
                      <span> 4 Hari / 3 Malam</span>
                    </div>
                  </div>

                  <p className="text-justify leading-7">{description}</p>

                  <div className="my-8">
                    <div className="text-sm font-semibold mb-8">Harga Termasuk</div>
                    <div className="flex gap-4">
                      {detail.includes.split(',').map(inc => (
                        <span className="bg-purple-100 text-purple-800 rounded py-3 px-8">
                          {inc}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </section>

              <div className="mt-8 mb-6">
                <h2 className="text-xl font-semibold">Rencana Perjalanan</h2>
                <div className="font-light text-gray-500">Rangkaian Perjalanan Anda</div>
              </div>

              <div className="itenary-container mb-36 lg:mt-16">
                <div className="itenary-wrapper 2xl:px-32">
                  {itenaries?.map(({ title, description, place, urls }, index) => (
                    <div key={index}>
                      <div className="border rounded-r inline-block p-1 px-3 font-semibold text-main-color border-main-color">
                        Hari {index + 1}
                      </div>
                      <div className="pl-5 pt-5 pb-8 border-l border-main-color">
                        <div className="md:grid md:grid-cols-2 md:gap-6">
                          <div className="basis-3/4">
                            <h2 className="font-bold text-lg mb-3">
                              {title} | {place}
                            </h2>
                            <p className="text-gray-600 pl-1 mb-3 text-justify">{description}</p>
                          </div>

                          <img
                            src={
                              urls.image === undefined
                                ? `https://placeholder.pics/svg/400`
                                : `https://temantrip.fun/${urls.image}`
                            }
                            alt="hari 1"
                            className="h-48 sm:h-64 object-cover w-full object-center rounded md:h-96"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="reservation hidden lg:block">
              <div
                className="bg-white min-w-fit rounded border whitespace-nowrap p-5 mt-20 sticky top-28"
                style={{ boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 16px' }}>
                <div className="price mb-5">
                  <strong className="text-xl">Rp. 4.500.000</strong> | 4 Hari / 3 Malam
                </div>
                <div className="trip-date grid grid-cols-2 mb-8 gap-0.5">
                  <div className="depart p-3 rounded border border-gray-200">
                    <div className="text-sm">Berangkat</div>
                    <div>21/03/2023</div>
                  </div>
                  <div className="return p-3 rounded border border-gray-200">
                    <div className="text-sm">Pulang</div>
                    <div>25/03/2023</div>
                  </div>
                </div>
                <Button className="w-full" onClick={handleOrder}>
                  PESAN
                </Button>
              </div>
            </div>
          </div>
        </main>
        <div className="border-t items-center gap-4 bg-white w-full fixed bottom-0 border-gray-200 p-3 flex justify-between px-5 whitespace-nowrap min-w-fit lg:hidden">
          <div>
            <div>
              <strong>Rp. 4.500.000</strong> | 4 Hari / 3 Malam
            </div>
            <div>21 Mar - 25 Mar</div>
          </div>
          <Button onClick={handleOrder}>PESAN</Button>
        </div>
      </>
    );
  }
};
export default Trip;
