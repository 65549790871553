import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getJwtFromLocal } from '../../utils/getJwt'

const initialState = {
  username: '',
  name: '',
  email: '',
  trip_leader: {},
  isOpenModal: false,
  isLoading: false,
  isError: false,
  isRefetch: false,
  message: '',
}

export const getVerificationsDetail = createAsyncThunk(
  'verifications/getVerificationsDetail',
  async (username) => {
    const { isLocal, jwt } = getJwtFromLocal('ADMIN')

    if (!isLocal) {
      return
    }

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/verifications/${username}`,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    const data = await res.json()
    return data
  }
)

export const verificationsSlice = createSlice({
  name: 'verifications',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isOpenModal = false
      state.username = ''
      state.name = ''
      state.email = ''
      state.trip_leader = {}
      state.isRefetch = false
      state.isLoading = false
      state.isError = false
    },
    openModal: (state) => {
      state.isOpenModal = true
    },
    refetchNow: (state) => {
      state.isRefetch = true
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVerificationsDetail.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getVerificationsDetail.fulfilled, (state, action) => {
      const { name, username, email, trip_leader } = action.payload.data
      state.username = username
      state.name = name
      state.email = email
      state.trip_leader = trip_leader
      state.isLoading = false
    })
    builder.addCase(getVerificationsDetail.rejected, (state, action) => {
      state.message = action.payload
      state.isLoading = false
      state.isError = true
      state.username = ''
      state.name = ''
      state.email = ''
      state.trip_leader = {}
    })
  },
})

export const { closeModal, openModal, refetchNow } = verificationsSlice.actions

export default verificationsSlice.reducer
