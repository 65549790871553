import { Link } from "react-router-dom";

const Button = ({
  children,
  className,
  style,
  secondary,
  onClick,
  small,
  link,
  type,
  isLoading = false,
  ...buttonProps
}) => {
  const buttonClass = secondary
    ? "border-main-color border-2 text-main-color font-semibold  hover:bg-main-color hover:text-white rounded hover:transition-all active:bg-main-dark"
    : `text-white font-semibold rounded bg-main-color border-2 border-main-color ${
        !isLoading && "hover:bg-transparent"
      } hover:text-main-color hover:transition-all whitespace-nowrap`;

  const buttonSize = small ? "py-1.5 px-4" : "py-3.5 px-8";

  if (link) {
    return (
      <Link
        to={link}
        className={`${buttonClass} ${buttonSize} ${className}`}
        style={style}
        onClick={onClick}
        {...buttonProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`${buttonClass} ${buttonSize} ${className}`}
      style={style}
      onClick={onClick}
      type={type}
      disabled={isLoading}
      {...buttonProps}
    >
      {isLoading ? (
        <svg
          className="animate-spin mx-auto h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        children
      )}
    </button>
  );
};
export default Button;
