import { Link, useParams } from 'react-router-dom'
import { IoIosDoneAll } from 'react-icons/io'
import { FaMoneyCheckAlt } from 'react-icons/fa'

export default function PaymentPayout({ children }) {
  const { slug } = useParams()
  return (
  <div className={div}>
    <h1 className='text-center mt-40 mb-20 font-bold text-3xl text-main-color'>Payment dan Payout</h1>

    <div className='grid grid-cols-2 gap-5 w-1/2 place-items-start pl-4 sm:pl-10'>
      <div className={title}>
        <IoIosDoneAll size='25' />
        <Link to={`/payout/${slug}`}><p>diterima</p></Link>
      </div>

      <div className={title}>
        <FaMoneyCheckAlt />
        <Link to={`/payout/${slug}/dicairkan`}><p>dicairkan</p></Link>
      </div>

      {children}
    </div>
  </div>
  )
}

const div = 'max-w-xl mx-auto'
const title = 'flex gap-5 items-center text-main-darkest hover:font-bold'
