import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsX } from 'react-icons/bs'
import { closeModal, refetchNow } from '../../features/admin/verificationSlice'
import { isEmptyObject } from '../../utils/utils'
import axios from 'axios'
import { getJwtFromLocal } from '../../utils/getJwt'
import { toast } from 'react-toastify'

const BASE_URL = 'https://temantrip.up.railway.app/'

export default function VerificationModal() {
  const dispatch = useDispatch()
  const { name, username, email, trip_leader, isLoading, isError } =
    useSelector((store) => store.verifications)

  const verifyTripLeader = async (username) => {
    const { isLocal, jwt } = getJwtFromLocal('ADMIN')

    if (!isLocal) {
      return
    }

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/admin/verifications/${username}`,
        { username },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      dispatch(refetchNow())
      if (res.status === 200) {
        toast.success('Berhasil di Verifikasi', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        dispatch(closeModal())
      }
    } catch (err) {
      toast.error(err?.response.data.message, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  useEffect(() => {
    if (isError) {
      // toast handle
    }
  }, [isLoading, isError])

  if (isLoading) {
    return <LoadingModal dispatch={dispatch} />
  }

  return (
    <div
      className='fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center'
      style={{ background: 'rgba(0, 0, 0, 0.3)' }}
    >
      <div className='bg-white w-[800px] max-w-full h-fit py-12 px-8 rounded-lg'>
        <div className='flex justify-between items-center'>
          <p className='text-main-color text-xl font-semibold'>
            Verifikasi Trip Leader
          </p>
          <button
            onClick={() => dispatch(closeModal())}
            className='text-gray-400 hover:text-gray-800 w-8 h-8 text-2xl font-semibold'
          >
            <BsX />
          </button>
        </div>
        <div className='mt-6 text-gray-400'>
          {!isEmptyObject(trip_leader?.verification) ? (
            <div className='w-64 h-20 overflow-hidden rounded-lg'>
              <img
                src={BASE_URL + trip_leader?.verification?.image}
                alt={trip_leader?.verification?.image}
              />
            </div>
          ) : null}
          <div className='grid grid-cols-2 gap-3 mb-1'>
            <p>Name</p>
            <p className='text-gray-700'>: {name}</p>
          </div>
          <div className='grid grid-cols-2 gap-3 mb-1'>
            <p>Email</p>
            <p className='text-gray-700'>: {email}</p>
          </div>
          <div className='grid grid-cols-2 gap-3'>
            <p>Username</p>
            <p className='text-gray-700'>: {username}</p>
          </div>
          {!isEmptyObject(trip_leader?.verification) ? (
            <>
              <div className='grid grid-cols-2 gap-3'>
                <p>NIK</p>
                <p className='text-gray-700'>
                  : {trip_leader?.verification?.nik}
                </p>
              </div>
              <div className='grid grid-cols-2 gap-3'>
                <p>Alamat</p>
                <p className='text-gray-700'>
                  : {trip_leader?.verification?.address}
                </p>
              </div>
            </>
          ) : null}
          {!isEmptyObject(trip_leader?.social_media) ? (
            <div className='my-3 border-t border-gray-300'>
              <p className='mt-2 text-lg text-main-color mb-3'>Social Media</p>
            </div>
          ) : null}
          {!isEmptyObject(trip_leader?.social_media)
            ? Object.entries(trip_leader?.social_media).map((t, k) => (
                <div className='grid grid-cols-2 gap-3' key={k}>
                  <p>{t[0]}</p>
                  <p className='text-gray-700'>: {t[1]}</p>
                </div>
              ))
            : null}
          <div className='flex justify-end'>
            <button
              onClick={() => verifyTripLeader(username)}
              className='h-[50px] w-1/3 rounded-full mt-8 text-white bg-[#5331CE]'
            >
              Verifikasi
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const LoadingModal = ({ dispatch }) => {
  return (
    <div
      className='fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center'
      style={{ background: 'rgba(0, 0, 0, 0.3)' }}
    >
      <div className='bg-white w-[800px] max-w-full h-fit py-12 px-8 rounded-lg'>
        <div className='flex justify-between items-center'>
          <p className='text-main-color text-xl font-semibold'>
            Verifikasi Trip Leader
          </p>
          <button
            onClick={() => dispatch(closeModal())}
            className='text-gray-400 hover:text-gray-800 w-8 h-8 text-2xl font-semibold'
          >
            <BsX />
          </button>
        </div>
        <div className='mt-6 text-gray-400'>
          <p>Loading...</p>
        </div>
      </div>
    </div>
  )
}
