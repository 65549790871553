import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: '',
  profile: {},
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    addProfile: (state, action) => {
      state.username = action.payload.username
      state.profile = { ...action.payload.role }
    },
    removeProfile: (state) => {
      state.username = ''
      state.profile = {}
    },
  },
})

export const { addProfile, removeProfile } = profileSlice.actions

export default profileSlice.reducer
