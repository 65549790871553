import { useState, useEffect, useCallback } from 'react';
import { AiOutlineGoogle, AiOutlineMail, AiOutlineClose } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Button from '../Form/Button';
import Input from '../Form/Input';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  name: yup.string().required('Name is required').min(3, 'Name must be minimum 3 characters'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  password: yup
    .string()
    .required('Password is requried')
    .min(8, 'Password must be atleast 8 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is requried'),
});

const SignupModal = ({ isOpen = false, handleCloseModal, handleLoginModal }) => {
  const [loginAuth, setLoginAuth] = useState('');
  const [isUseEmail, setIsUseEmail] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEscKey = useCallback(
    e => {
      if (e.key === 'Escape') {
        handleCloseModal();
        setIsUseEmail(false);
      }
    },
    [handleCloseModal],
  );

  useEffect(() => {
    document.addEventListener('keyup', handleEscKey);
    return () => {
      document.removeEventListener('keyup', handleEscKey);
    };
  }, [handleEscKey]);

  const handleSwitchModal = () => {
    handleCloseModal();
    handleLoginModal();
    setIsUseEmail(false);
  };

  const showToast = () =>
    toast.success('Pendaftaran berhasil!, silahkan masuk', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const handleRegister = data => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/register`, data)
      .then(function (response) {
        if (response.status === 201) {
          handleSwitchModal();
          setValue('name', '');
          setValue('password', '');
          setValue('email', '');
          setIsRegisterLoading(false);
          showToast();
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsRegisterLoading(false);

        toast.error('Terjadi kesalahan, silahkan coba lagi', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const SignupOptions = () => {
    return (
      <>
        <div className="text-sm font-light text-slate-500 mb-1.5 pl-0.5">Lanjutkan dengan...</div>
        <div className="flex gap-2 mb-6">
          <a href={`${process.env.REACT_APP_API_URL}/auth/google`}>
            <button
              className="p-3 w-28 h-24 border rounded text-sm hover:border-main-color transition-all">
              <AiOutlineGoogle size={24} className="mx-auto mb-4" />
              <span>Google</span>
            </button>
          </a>
          <button className="p-3 w-28 h-24 border rounded text-sm hover:border-main-color transition-all">
            <RiFacebookFill size={24} className="mx-auto mb-4" />
            <span>Facebook</span>
          </button>
          <button
            onClick={() => setIsUseEmail(true)}
            className="p-3 w-28 h-24 border rounded text-sm hover:border-main-color transition-all">
            <AiOutlineMail size={24} className="mx-auto mb-4" />
            <span>Email</span>
          </button>
        </div>
      </>
    );
  };

  const SignupWithEmail = () => {
    return (
      <form
        onSubmit={handleSubmit(data => {
          setIsRegisterLoading(true);
          handleRegister(data);
        })}
        className="register-form -mt-20">
        <Input
          className="w-full"
          type="text"
          placeholder="Nama Lengkap"
          name="name"
          {...register('name')}
          errors={errors?.name?.message}
        />
        <Input
          className="w-full"
          name="email"
          id="signup_email"
          placeholder="Email"
          {...register('email')}
          errors={errors?.email?.message}
        />
        <Input
          className="w-full"
          type="password"
          name="password"
          id="signup_password"
          placeholder="Password"
          {...register('password')}
          errors={errors?.password?.message}
          minLength={6}
        />
        <Input
          className="w-full"
          type="password"
          id="confirm_password"
          placeholder="Password"
          {...register('confirmPassword')}
          errors={errors?.confirmPassword?.message}
          minLength={6}
        />
        <Button className="w-full mb-3 min-h-[49.5px]" type="submit" isLoading={isRegisterLoading}>
          Daftar
        </Button>
      </form>
    );
  };

  return (
    <div
      className={`modal-container fixed z-50 top-0 w-full h-full ${!isOpen && 'hidden'}`}
      style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
      <div className="modal-wrapper h-screen flex">
        {isOpen && (
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.15 }}
            className="inner-modal bg-white w-[48rem] m-auto rounded flex">
            <div className="grow flex rounded-l">
              <img
                src="https://images.unsplash.com/photo-1675154092378-1ca4978ec6a5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
                alt="floating"
                className="object-center object-cover rounded-l"
              />
            </div>
            <div className="basis-1/2 shrink-0 p-10 pb-6 min-w-[378px]">
              <div className="flex justify-between mb-1.5">
                <h2 className="text-2xl font-bold text-main-darker">Daftar</h2>
                <button
                  className="close-btn -mt-10 -mr-6 px-3 hover:opacity-40 opacity-60 hover:transition-all"
                  onClick={() => {
                    handleCloseModal();
                    setIsUseEmail(false);
                  }}>
                  <AiOutlineClose size={18} strokeWidth={36} />
                </button>
              </div>
              <p className="text-sm font-light mb-32 text-slate-500">
                Bergabung & Mulai petualanganmu sekarang juga!
              </p>

              {isUseEmail ? <SignupWithEmail /> : <SignupOptions />}

              <div className="mb-16">
                <p className="text-sm pl-0.5 text-slate-500">
                  Sudah punya akun?&nbsp;
                  <button
                    onClick={() => handleSwitchModal()}
                    className="text-main-color hover:underline">
                    Masuk
                  </button>
                </p>
              </div>
              <p className="text-xs text-slate-500 text-center">
                Dengan mendaftar, saya menyetujui&nbsp;
                <Link to="/" className="text-main-color hover:underline">
                  Syarat dan Ketentuan
                </Link>
                &nbsp;serta&nbsp;
                <Link to="/" className="text-main-color hover:underline">
                  Kebijakan Privasi
                </Link>
              </p>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default SignupModal;
