import { useState } from 'react';
import ttripLogo from '../../assets/images/ttrip.png';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineWhatsApp, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { IoNotificationsOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import Avatar, { genConfig } from 'react-nice-avatar';
import UserDropdown from '../UserDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/authSlice';

const Nav = ({ handleLoginModal, handleSignupModal }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const name = JSON.parse(localStorage.getItem('name'));
  const username = useSelector(state => state.auth.username) || '';
  const user = JSON.parse(localStorage.getItem('user'));
  const isTripLeader = useSelector(state => state.auth.isTripLeader) || '';
  const dispatch = useDispatch();

  const location = useLocation();
  const avatarConfig = genConfig(username);
  const navClass =
    location.pathname === '/'
      ? 'border-b sm:border-0 border-gray-200 bg-white sm:bg-transparent container'
      : 'bg-white fixed w-full px-12 z-50';

  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch(logout());
  };

  return (
    <div>
      <nav className={`mx-auto pt-5 top-0 pb-3 whitespace-nowrap ${navClass}`}>
        <div className="nav-wrapper flex gap-3 items-center font-bold uppercase">
          <div className="flex-grow shrink-0 min-w-[238px] sm:min-w-0">
            <Link to="/">
              <img className="h-12 inline" src={ttripLogo} alt="Teman trip logo" />
            </Link>
          </div>
          <div className="overflow-x-auto hide-scrollbar gap-3 hidden sm:flex">
            <Link to="/trips" className="nav-list">
              Ikut Trip
            </Link>
            <Link to="/newTrips" className="nav-list">
              Buat Trip
            </Link>
            <Link to="/carakerja" className="nav-list">
              Cara Kerja
            </Link>
            {username || user ? (
              <div className="grid grid-cols-2 justify-items-center gap-6 items-center ml-5">
                <div className="avatar-wrapper relative">
                  <UserDropdown
                    title={name ? name : username}
                    username={name ? name : username}
                    containerClassName="top-5 -ml-5"
                    buttonClassName="m-auto"
                    handleLogout={handleLogout}
                    isTripLeader={isTripLeader}>
                    <Avatar className="w-9 h-9 border-2 border-main-color" {...avatarConfig} />
                  </UserDropdown>
                </div>
                <button className="notification">
                  <IoNotificationsOutline size={26} className="-mt-2" color="rgb(75 85 99)" />
                </button>
              </div>
            ) : (
              <>
                <button
                  onClick={() => handleSignupModal()}
                  className="nav-list text-main-color uppercase">
                  Daftar
                </button>
                <button
                  className="nav-list text-main-color uppercase"
                  onClick={() => handleLoginModal()}>
                  Masuk
                </button>
              </>
            )}
          </div>
          <button className="p-2 ml-2 grayscale hover:grayscale-0 transition-all hidden sm:block">
            <a href="https://api.whatsapp.com/send/?phone=6282144234649&text=Hi&type=phone_number&app_absent=0">
              <AiOutlineWhatsApp size={28} color="#25d366" className="-mt-2" />
            </a>
          </button>
          {isMenuOpen ? (
            <button
              className="sm:hidden opacity-60 outline-none"
              onClick={() => setIsMenuOpen(false)}>
              <AiOutlineClose size={28} className />
            </button>
          ) : (
            <button
              className="sm:hidden opacity-60 outline-none"
              onClick={() => setIsMenuOpen(true)}>
              <AiOutlineMenu size={28} className />
            </button>
          )}
        </div>
      </nav>
      {isMenuOpen && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'fit-content', opacity: 1 }}
          transition={{ duration: 0.15, ease: 'easeIn' }}
          className={`mobile-menu-wrapper fixed top-16 mt-0.5 px-6 py-5 left-0 flex flex-col w-full bg-white z-10 font-bold sm:hidden text-center border-b border-gray-300 uppercase`}>
          <Link to="/trips" className="mobile-nav-list">
            Ikut Trip
          </Link>
          <Link to="/" className="mobile-nav-list">
            Buat Trip
          </Link>
          <Link to="/carakerja" className="mobile-nav-list">
            Cara Kerja
          </Link>

          {username || user ? (
            <span></span>
          ) : (
            <>
              <button
                onClick={() => handleSignupModal()}
                className="mobile-nav-list text-main-color uppercase">
                Daftar
              </button>
              <button
                className="mobile-nav-list text-main-color uppercase"
                onClick={() => handleLoginModal()}>
                Masuk
              </button>
            </>
          )}
        </motion.div>
      )}
    </div>
  );
};
export default Nav;
