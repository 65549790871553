import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loading from '../Components/Fallbacks/LoadingSkeleton';

const PageNotFound = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get('state');
  const code = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(() => {
    if (state && code) {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/google/callback?state=${state}&code=${code}`)
        .then(function (response) {
          if (response.status === 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
            navigate('/trips');
          }
        })
        .catch(function (error) {
          toast.error('Terjadi kesalahan: ' + error?.message, {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        });
      setLoading(false)
    } else {
      console.log('not found');
    }
  }, []);

  return (
    <main className="flex justify-center items-center h-screen">
      {loading ? (
        <Loading />
      ) : (
        <div className="text-center">
          <h1 className="text-xl font-semibold mb-3">404 | Page Not Found</h1>
          <Link to="/" className="hover:underline">
            Back to homepage
          </Link>
        </div>
      )}
    </main>
  );
};
export default PageNotFound;
