import Image1 from '../assets/images/imagetrip1.svg'
import Image2 from '../assets/images/imagetrip2.svg'
import Image3 from '../assets/images/imagetrip3.png'
import { Link } from 'react-router-dom'
import Banner from '../assets/images/Cover-Image.png'
import BannerMobile from '../assets/images/Cover-Image-mobile.png'
import { BsFillShieldFill as Shield } from 'react-icons/bs'
import Unique from '../assets/icons/IconsUnique.svg'

export default function CaraKerja() {

  const Card = ({ img, title, desc }) => {
    return <div className='max-w-6xl mx-auto flex flex-col sm:flex-row gap-10 md:gap-5'>
      <div className='w-full mx-auto '>
        <img src={img} className='rounded' alt='preview' />
      </div>

      <div className='h-40 w-full lg:pr-20'>
      <h2 className='mb-3 font-semibold text-main-color'>{title}</h2>
      <p className='text-sm text-main-darkest pr-4'>{desc}</p>
      </div>
    </div>
  }

  return <section className=' mt-36 w-full flex flex-col items-center'>
    <div className='sm:px-10 max-w-6xl mx-auto w-full'>
      <img className='hidden sm:block' src={Banner} alt="banner" />
      <img className='sm:hidden block mx-auto w-full px-5' src={BannerMobile} alt="banner" />
    </div>

    <div className='mt-20 sm:my-36 flex flex-col items-center px-5 gap-10 sm:px-10 md:px-20'>
      <Card img={Image1} title='Explore'
        desc='Jelajahi berbagai trip yang ada di temantrip pilih destinasi wisata dan
        itinary yang kamu suka. Trip leader yang somehow connected with you! Youy gotta need that chemistry'
      />
      <Card img={Image2} title='Join Trip' 
        desc='Jelajahi berbagai trip yang ada di temantrip pilih destinasi wisata dan
        itinary yang kamu suka. Trip leader yang somehow connected with you! Youy gotta need that chemistry'
      />
      <Card img={Image3} title='Travel'
        desc='Jelajahi berbagai trip yang ada di temantrip pilih destinasi wisata dan
        itinary yang kamu suka. Trip leader yang somehow connected with you! Youy gotta need that chemistry'
      />
    </div>

    <div className={divTextBawah}>
    <div className='max-w-6xl mx-auto grid grid-cols-2 md:grid-cols-3 gap-5 text-center px-10 p-3 '>
      <div className='col-span-2 place-self-start md:col-span-3 text-start font-medium text-white py-4 md:font-lg md:pr-96'>
        <h5>Travel bersama teman yang sefrekuensi bikin perjalan kamu lebih menyenangkan dan pastinya anti boring!</h5>
      </div>


      <div>
        <div className='text-main-color bg-white rounded-full p-4 w-12 h-12 mx-5 mb-1 self-center'>
          <img src={Unique} alt="icons Unique" />
        </div>
        <h4 className={h4}>Unique Travel</h4>
        <p className={p}>Setiap trip disusun unik dengan ciri khas masing-mmasing. Tidak ada dua trip yang sama disini! Trip yang tidak akan kalian temukan dimanapun!</p>
      </div>

      <div>
        <div className='text-main-color bg-white rounded-full p-4 w-12 h-12 mx-5 mb-1 self-center'> <Shield />
        </div>
        <h4 className={h4}>Secure Payment</h4>
        <p className={p}>Pembayaran yang aman. Kita pastikan uang kamu aman. Kamu pasti akan pergi pada trip yang kamu bayar.</p>
      </div>

      <div className={btn}>
        <button type="submit"><Link to='/trips'>Explore Now</Link></button>
      </div>
    </div>
    </div>
  </section>
}

const divTextBawah = 'bg-main-dark w-full'
const h4 = 'text-white font-semibold text-start'
const p = 'text-md text-[#fff] text-medium mt-3 text-start text-[10px]'
const btn = 'bg-white col-span-2 md:col-span-1 items-center text-center text-sm text-main-color hover:bg-main-darker w-full md:w-40 md:h-14 mb-10 md:mb-0 font-medium rounded-full py-4'

