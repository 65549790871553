import Input from './Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Payout from '../../Pages/PaymentPayout';
import { useParams } from 'react-router-dom';

export default function Dicairkan() {
  const { slug } = useParams();

  const valid = yup.object().shape({
    bank: yup.string().required('Nama Bank harus di isi!'),
    norek: yup.string().required('Nomor Rekening wajib di isi!'),
    ktp: yup.string().required('Nama Rekening harus di isi!'),
    jumlah: yup.string().required('Masukan jumlah nya!'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(valid),
  });

  const callBackSubmit = data => {
    data.preventDefault();
  };
  return (
    <Payout>
      <div className="col-span-2 mb-20">
        <form className={form} onSubmit={handleSubmit(callBackSubmit)}>
          <h1 className={h1}>Detail Bank</h1>
          <Input label="Nama Bank" type="text" id="bank" place="" {...register('bank')} />
          <span className={span}>{errors.bank?.message}</span>

          <Input
            label="Nomor Rekening"
            type="number"
            id="norek"
            place="xxxxx xxxxx xxxxxx"
            {...register('norek')}
          />
          <span className={span}>{errors.norek?.message}</span>

          <Input
            label="Rekening Atas Nama (harus sama dengan KTP yang didaftarkan)"
            type="text"
            id="ktp"
            place=""
            {...register('ktp')}
          />
          <span className={span}>{errors.ktp?.message}</span>

          <h1 className={h1}>Detail Penciran</h1>
          <Input
            label="jumlah yang akan di cairkan"
            type="number"
            id="jumlah"
            place="50.000.000"
            {...register('jumlah')}
          />
          <span className={span}>{errors.jumlah?.message}</span>

          <button className={btn} type="submit">
            Submit
          </button>
        </form>
      </div>
    </Payout>
  );
}

const form = 'grid my-2 grid-cols-1 ';
const h1 = 'font-bold text-lg my-5 text-main-color';
const span = 'mb-3 text-red-500 text-xs';
const btn = 'my-10 p-1 bg-violet-500 text-white font-bold px-5 w-24 rounded-full outline-none';
