import { useState } from 'react'
import { AiFillHome } from 'react-icons/ai'
import { BsChevronDown } from 'react-icons/bs'
import { TbNotes } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

export default function SidebarAdmin() {
  const navigate = useNavigate()
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false)
  return (
    <div className='fixed top-0 left-0 w-[270px] min-h-screen bg-white pt-28 flex flex-col gap-3'>
      <button className='p-3 w-full flex gap-3' onClick={() => navigate('/admin/dashboard')}>
        <div className='text-xl text-gray-600'>
          <AiFillHome />
        </div>
        <p>Dashboard</p>
      </button>
      {/* menus request */}
      <div className='w-full h-fit'>
        <button
          onClick={() => setIsOpenSubmenu(!isOpenSubmenu)}
          className={`p-3 w-full flex gap-3 justify-between ${
            isOpenSubmenu ? 'bg-[#DDD6F5] text-[#4529AC]' : 'text-gray-600'
          }`}
        >
          <div className='flex gap-4'>
            <div className='text-xl'>
              <TbNotes />
            </div>
            <p>Pengajuan</p>
          </div>
          <div className='text-xl'>
            <BsChevronDown />
          </div>
        </button>
        {isOpenSubmenu
          ? menuSubmission.map(({ name, path }, index) => (
              <button
                className='p-3 pl-12 text-left w-full bg-[#DDD6F5]'
                key={index}
                onClick={() => navigate(`/admin/${path}`)}
              >
                <p
                  // className={
                  //   currentPath === path ? 'text-[#4529AC]' : 'text-[#4F4F4F]'
                  // }
                >
                  {name}
                </p>
              </button>
            ))
          : null}
      </div>
    </div>
  )
}

const menuSubmission = [
  {
    name: 'Trip Leader',
    path: 'verifications',
  },
  {
    name: 'Payment',
    path: 'payments',
  },
]
